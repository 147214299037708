(function () {
    "use strict";

    // @ngInject
    function LoginControllerCtor($scope, $injector, $stateParams, $location, RegexService, ClientPortalService, $window) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'LoginController';

        this.$stateParams = $stateParams;
        this.ClientPortalService = ClientPortalService;
        this.RegexService = RegexService;
        this.$location = $location;
        this.$window = $window;
        
        console.debug("isInClientPortal");
        if (this._isInClientPortal()) {
            console.debug("True - isInClientPortal");
            this.showClientPortal = true;
        }

        $window.performance && $window.performance.mark('hb_login_shown');
    }

    Controllers.LoginController = Class(Controllers.BaseController, {

        constructor: LoginControllerCtor,

        _isInClientPortal: function _isInClientPortal() {
            return this.ClientPortalService.isInClientPortal() && !this.$stateParams.vendor;
        }
    });
}());
