(function () {
    "use strict";

    // @ngInject
    function CoreControllerCtor($scope, $injector, $log, $timeout, $window, Gon,$document,
                                OnboardingService, ModalService, $location,
                                UsersManager, WebsocketHelperService, ClientCacheService, AppConfigService, $stateParams,
                                NavigationService, DeviceService, NetworkConnectionService,
                                hotkeys, SearchV2Service, AnalyticsService, OtamStatusService, FinanceAppService, $sce,
                                FeatureRestrictionService, FeaturesService, GlobalBannerService, StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        var self = this;
        this.__objectType = 'CoreController';
        this.OnboardingService = OnboardingService;
        this.NavigationService = NavigationService;
        this.UsersManager = UsersManager;
        this.AnalyticsService = AnalyticsService;
        this.$log = $log;
        this.currUser = null;
        this.WebsocketHelperService = WebsocketHelperService;
        this.ClientCacheService = ClientCacheService; // not used here - but is a core service that needs to be loaded - do not remove
        this.NetworkConnectionService = NetworkConnectionService; // not used here - but is a core service that needs to be loaded - do not remove
        this.window = $window;
        this.DeviceService = DeviceService;
        var checkifMobileAndroid = this.DeviceService.isAndroid();
        this.isInAppBrowser = this.DeviceService.isInAppBrowser();
        this.isTouchDevice = this.DeviceService.checkIfTouchDevice();
        var currUser = UsersManager.getCurrUser();
        this.OtamStatusService = OtamStatusService;
        this.FeatureRestrictionService = FeatureRestrictionService;
        this.FeaturesService = FeaturesService;
        this.GlobalBannerService = GlobalBannerService;
        this.email_verification_succeeded = $stateParams.email_verification_succeeded;
        this.is_on_quiz = false;
        this.is_on_link_resolve = true;
        this.StatsigService = StatsigService;

        this.StatsigService.isGateEnabled('ng-2-react-graceful-reload').then(function(gracefulReloadInReact) {
            self.gracefulReloadEnabled = !gracefulReloadInReact;
        });

        var setIsOnLinkResolve = function() {
            self.is_on_link_resolve = $location.path().includes('link/resolve/');
        };
        var setIsOnQuiz = function() {
            self.is_on_quiz = $location.path().includes('welcome');
        };

        if(!currUser.email_verified) {
            setIsOnQuiz();
        }

        $scope.$on('$locationChangeSuccess', function() {
            if(!currUser.email_verified) {
                setIsOnQuiz();
            }

            setIsOnLinkResolve();
        });

        setIsOnLinkResolve();

        // We allow support login to non active and fraud accounts
        if (currUser && currUser.isAdminLogin() && !currUser.isAdmin()) {
            if (currUser.account_status && currUser.account_status !== 'active') {
                this.accountStatusNotValidState = currUser.account_status.replace(/_/g, " ");
            } else if (currUser.fraud_status && currUser.fraud_status === 'certain_fraud') {
                this.accountStatusNotValidState = currUser.fraud_status.replace(/_/g, " ");
            }
        }

        if(UsersManager.isLoggedIn()){
            this.OtamStatusService.listenToMigration();
        }
        this.isAndroidMobile = false;
        if(checkifMobileAndroid){
            this.isAndroidMobile = true;
        }

        var _this = this;

        this.checkIfTrialExtension = function setOnboarding() {
            var currUser = UsersManager.getCurrUser();
            if (currUser && currUser._id) {
                var callback = function () {
                    $timeout(function () {
                        if (currUser.trial_extension_data && !currUser.hasTrialExpiredDueToNoPayment()) {
                            if (currUser.trial_extension_data.is_extended && currUser.trial_extension_data.extension_reason === "retargeting_ad" &&
                                currUser.trial_extension_data.extension_type === "days" && currUser.trial_extension_data.extension_amount) {
                                ModalService.openTrialExtendedModal(currUser.trial_extension_data.extension_amount);
                            }
                            delete currUser.trial_extension_data; //clear from curr user object
                        }
                    }.bind(this), 500);
                }.bind(this);

                UsersManager.checkForTrialExtension(currUser).then(callback).catch(callback); //should've used finally but not supported on IE & Edge
            }
        }.bind(this);

        var searchCombo = 'mod+k';
        var searchComboRegistered = false;
        this.deregisterSearchShortcut = function registerSearchShortcut() {
            if (searchComboRegistered) {
                searchComboRegistered = false;
                hotkeys.del(searchCombo);
            }
        }.bind(this);

        this.registerSearchShortcut = function registerSearchShortcut() {
            var currUser = UsersManager.getCurrUser();
            if (!searchComboRegistered && !(currUser && currUser.isClient())) {
                var description = 'Search';
                searchComboRegistered = true;
                hotkeys.bindTo($scope).add({
                    persistent: true,
                    combo: searchCombo,
                    description: description,
                    callback: function loadSearchV2() {
                        AnalyticsService.track(
                            this,
                            AnalyticsService.analytics_events.hotkey_used,
                            {combo: searchCombo, action: description});
                        SearchV2Service.open();
                    }.bind(this)
                });
            }
        }.bind(this);

        this.registerToCompanyModel = function registerToCompanyModel() {
            var currUser = UsersManager.getCurrUser();
            if(currUser && currUser._id) {
                this.unregisterCompanyModel();
                this.currUser = currUser;
                this.register(this.currUser.company, 'success', function handleCompanyModelUpdated(){
                    this.checkIfTrialExtension();
                    this.FeatureRestrictionService.checkIfAccountCanceledOnCompanyChange();
                }.bind(this));
            }
        };

        this.trackPixel = function trackPixel() {
            if (!this.window.isClientPortal()) {
                var currUser = UsersManager.getCurrUser();
                if (currUser && currUser._id) {
                    if (!this.isTouchDevice && currUser.onboarding_container && currUser.onboarding_container.onboarding_start_device === 'mobile') {
                        this.AnalyticsService.reportEvent('MobileOnDesktop', {'currency': 'USD', 'value': 0});
                    }
                }

                this.AnalyticsService.reportEvent('PageView');
            }
        };

        this.trackPixel();

        this.unregisterCompanyModel = function unregisterCompanyModel() {
            if (this.currUser && this.currUser.company) {
                this.unregister(this.currUser.company);
            }
            this.currUser = null;
        };

        this.registerToCompanyModel();

        this.registerToOtamMigration = function() {
            this.OtamStatusService.initializeAfterLogin();
            this.OtamStatusService.listenToMigration();
        };

        this.unregisterToOtamMigration = function() {
            this.OtamStatusService.closeInProgressToast();
        };

        UsersManager.on('loggingOut', function handleLoggingOut() {
            this.checkIfTrialExtension();
            this.accountStatusNotValidState = null;
            this.unregisterCompanyModel();
            this.deregisterSearchShortcut();
            this.unregisterToOtamMigration();
        }.bind(this));

        UsersManager.on('loggingIn', function handleLoggingIn() {
            this.checkIfTrialExtension();
            this.registerToCompanyModel();
            this.trackPixel();
            this.registerSearchShortcut();
            this.registerToOtamMigration();
            this.FeatureRestrictionService.checkIfBlockedAccess({
                source: 'login',
                actionType: 'load'
            });
        }.bind(this));

        UsersManager.on('resetPsw', function handleLoggingIn() {
            this.checkIfTrialExtension();
            this.registerToCompanyModel();
        }.bind(this));

        // emitted from second level nav, rebroadcast down for people clients
        $scope.$on('emitContactsAdded', function(event, data) {
            this.$scope.$broadcast('broadcastContactsAdded', data);
        }.bind(this));

        this.registerSearchShortcut();

        $(window).on('scroll', function() {
            if (this.pageYOffset > 0) {
                $('body').addClass('page-is--scrolled');
            } else {
                $('body').removeClass('page-is--scrolled');
            }
        });

        $(window).on('load', function () {
            if (UsersManager.shouldOpenOooInEffectModal()) {
                ModalService.openOooInEffectModal();
            } else if (UsersManager.shouldOpenOooHasEndedModal()) {
                ModalService.openOooHasEndedModal();
            }
        });
    }

    Controllers.CoreController = Class(Controllers.BaseController, {

        constructor: CoreControllerCtor,

        isRouteActive: function isRouteActive(root) {
            return this.$state.current.name && this.$state.current.name.indexOf(root) !== -1;
        },

        dismissLoanOffer: function dismissLoanOffer() {
            this.UsersManager.dismissLoanOffer();
        },

        closeAccountStatusNotValidState: function closeAccountStatusNotValidState() {
            this.accountStatusNotValidState = null;
        }
    });
}());
