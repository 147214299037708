(function () {
    'use strict';

    // @ngInject
    function PendingTasksManagerCtor($q, $timeout, _, PubSubService, UsersManager, APIService, Routes, $log, ModelFactory, WebsocketHelperService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);
        PubSubService.ventMyBitchUp(this);

        this._ = _;
        this.$log = $log;
        this.$timeout = $timeout;
        this.Routes = Routes;
        this.WebsocketHelperService = WebsocketHelperService;
        this.UsersManager = UsersManager;

        this.excutingTasksHash = {};
        this.maxPollCount = 900;

        UsersManager.on('loggingOut', this._onLogoutCleanup.bind(this));
        UsersManager.on('loggingIn', this._onLogin.bind(this));
        WebsocketHelperService.on('websocket_connected', this.onWebsocketConnected.bind(this));
        WebsocketHelperService.on('websocket_disconnected', this.onWebsocketDisconnected.bind(this));

        if (UsersManager.isLoggedIn()) {
            WebsocketHelperService.registerToRoom(UsersManager.getCurrUser()._id + ".client_pending_tasks", this.onClientPendingTaskUpdate.bind(this));
        } else {
            console.debug("Start polling");
        }
    }

    Services.PendingTasksManager = Class(Services.BaseModelManager, {
        constructor: PendingTasksManagerCtor,

        pendingTasksTypes: {
            changePipelineStages: 'change_pipeline_stages',
            refundPayment: 'refund_payment',
            getUserExtraDetails: 'getUserExtraDetails',
            // getUserFraudScore: 'getUserFraudScore',
            brochureQuickSend: 'brochure_quick_send',
            removeTeamMember: 'remove_team_member',
            rebuildNetworkFeed: 'rebuild_network_feed',
            assignTeamMember: 'assign_team_member',
            generateReport: 'generate_report',
            deleteWorkspaces: 'delete_workspaces',
            archiveWorkspaces: 'archive_workspaces',
            moveWorkspacesStage: 'move_workspaces',
            contactsCsvImport: 'contacts_csv_import',
            mergeVendorsAccounts: 'merge_vendors_accounts',
            privacyDataWipeAccount: 'privacy_data_wipe_account',
            sendFile: 'send_file',
            approveReferralsCommission: 'approve_referrals_commission',
            plaidReadTransactions: 'plaid_read_transactions',
            yearlyReview: 'yearly_review',
            sendBulkEmailToWorkspaces: 'send_bulk_email_to_workspaces',
            sendBatchEmail: 'send_batch_email',
            deleteContacts: 'delete_contacts',
            sendRescheduleProjectEmail: 'send_reschedule_project_email',
            otamMigrateUser: 'otam_migrate_user',
            upsertBankAccountOwner: 'upsert_bank_account_owner',
            approveCompanyInvite: "approve_member_company_invite",
            shareLeadForm: 'share_lead_form',
            createAndShareLeadForm: 'create_and_share_lead_form',
            submitLeadForm: 'submit_lead_form',
            sendWorkspaceMessage:'send_workspace_message',
            exportCanvaDesigns: 'export_canva_designs',
            fetchCanvaDesigns: 'fetch_canva_designs'
        },

        pendingTasksStatuses: {
            pending: 0,
            started: 1,
            finished: 2,
            aborted: 3,
            canceled: 4
        },

        createPendingTask: function createPendingTask(taskType, taskData, returnApiPromise, onCreateTaskCb ) {
            var url = this.Routes.v2_create_client_pending_task_path({taskType: taskType});
            var createPromise = this.apiCreate(url, {task_type: taskType, task_data: taskData});

            var taskDeferred = this.$q.defer();
            createPromise.then(
                function success(response) {
                    var taskNode = {
                        taskId: response.data.task_id,
                        taskType: taskType,
                        taskData: taskData,
                        taskDeferred: taskDeferred,
                        taskPromise: taskDeferred.promise,
                        timeoutPromise: null,
                        canceled: false,
                        pollCount: 0
                    };

                    if (typeof onCreateTaskCb === 'function') {
                        onCreateTaskCb(response.data.task_id);
                    }

                    this.registerPendingTask(taskNode);
                }.bind(this),
                function fail(error) {
                    taskDeferred.reject(error);
                }.bind(this));

            if(returnApiPromise) {
                return createPromise;
            } else {
                return taskDeferred.promise;
            }
        },

        cancelPendingTask: function cancelPendingTask(taskId, cancelMessage) {
            var url = this.Routes.v2_cancel_client_pending_task_path(taskId);
            return this.apiDelete(url, {cancel_message: cancelMessage});
        },

        joinPendingTask: function joinPendingTask(taskType, taskFinishedCallback, taskUpdatedCallback,
                                                  onSuccessCallback, noResponseCallback, onErrorCallback) {
            var url = this.Routes.v2_check_client_pending_tasks_path();
            this.apiFetch(url, {task_type: taskType, task_states: ['started', 'pending']}).then(function success(response) {
                if (!(response && response.data && response.data[0] && response.data[0]._id)) {
                    if (angular.isFunction(noResponseCallback)) {
                        noResponseCallback();
                    }
                } else {
                    var taskDeferred = this.$q.defer();
                    taskDeferred.promise.then(taskFinishedCallback, null, taskUpdatedCallback);

                    var task = {
                        taskId: response.data[0]._id,
                        taskType: taskType,
                        taskData: null,
                        taskDeferred: taskDeferred,
                        taskPromise: taskDeferred.promise,
                        timeoutPromise: null,
                        canceled: false,
                        pollCount: 0
                    };

                    this.registerPendingTask(task);

                    if (angular.isFunction(onSuccessCallback)) {
                        onSuccessCallback();
                    }
                }
            }.bind(this)).catch(function catchHandler() {
                if (angular.isFunction(onErrorCallback)) {
                    onErrorCallback();
                }
            });
        },

        cancelTaskPolling: function cancelPendingTask(taskPromise) {
            var taskNodeToCancel = this._.findWhere(this.excutingTasksHash, {taskPromise: taskPromise});
            if(taskNodeToCancel){
                this._cancelPendingTask(taskNodeToCancel);
            } else {
                this.$log.error('could not find the task promise to cancel. could it be that you saved the wrong promise?');
            }
        },

        pollingInterval: function pollingInterval(){

            //if websocket is connected we still do polling in case of event not coming through, but at a
            //lower frequency
            if(this.WebsocketHelperService.isConnected()){
                return 6000;
            }else{
                return 2000;
            }
        },

        //do some cleaning since this service is a singleton
        _onLogoutCleanup: function _onLogoutCleanup() {
            //stop the polling on all the timeouts waiting to happen
            this._.forEach(this.excutingTasksHash, function taskHashIteratore(taskNode) {
                this._cancelPendingTask(taskNode);
            }, this);

            //throw the existing hash letting it be garbage collected.
            this.excutingTasksHash = {};
            this._cancelPolling();
        },

        _onLogin: function _onLogin() {
            console.debug("Connecting to pending");
            this.WebsocketHelperService.registerToRoom(this.UsersManager.getCurrUser()._id + ".client_pending_tasks", this.onClientPendingTaskUpdate.bind(this));
        },

        _cancelPendingTask: function _cancelPendingTask(taskNode) {
            taskNode.canceled = true;
            delete this.excutingTasksHash[taskNode.taskId];
        },

        _pendingTasksTimeoutHandler: function _pendingTasksTimeoutHandler() {
            if(!this._.isEmpty(this.excutingTasksHash)) {
                var url = this.Routes.v2_check_client_pending_tasks_path();
                this.apiFetch(url, {task_ids: Object.keys(this.excutingTasksHash)})
                    .then(function success(response) {
                        this._.forEach(Object.keys(this.excutingTasksHash), function (taskKey) {
                            var taskNode = this.excutingTasksHash[taskKey];
                            var task = this._.findWhere(response.data, {_id: taskNode.taskId});

                            if(taskNode.canceled || !task) {
                                // this means we have in our hash a task which is not monitored in the backend
                                // so we need to cancel it
                                taskNode.taskDeferred.reject('canceled');
                                delete this.excutingTasksHash[taskNode.taskId];
                                if(!task) {
                                    this.DatadogRUMService.addError(
                                        new Error('Found a client pending task which is not monitored on the server'),
                                        {
                                            task: task,
                                            url: url,
                                        }
                                    );
                                }
                                return;
                            }

                            this._handlePendingTaskUpdate(task);

                            if(taskNode.pollCount >= this.maxPollCount){
                                //unknown or aborted
                                taskNode.taskDeferred.reject('Client pending task timed out');
                                this._cancelPendingTask(taskNode);
                            }

                            taskNode.pollCount++;
                        }, this);
                    }.bind(this));
            }

            if(!this._.isEmpty(this.excutingTasksHash)) {
                this.timeoutPromise = this.$timeout(function consecutiveTimeoutHandler() {
                    // schedule next polling
                    this._pendingTasksTimeoutHandler();
                }.bind(this), this.pollingInterval());
            } else {
                this.timeoutPromise = null;
            }
        },

        _initiatePolling: function _initiatePollingOnTask(delayFirstPolling) {
            if(!this.timeoutPromise && !this._.isEmpty(this.excutingTasksHash)) {
                //the first call starts immediately
                this.timeoutPromise = this.$timeout(function initTimeoutHandler() {
                    this._pendingTasksTimeoutHandler();
                }.bind(this), delayFirstPolling ? this.pollingInterval() : 0);
            }
        },

        _cancelPolling: function _cancelPolling() {
            this.$timeout.cancel(this.timeoutPromise);
            this.timeoutPromise = null;
        },

        onClientPendingTaskUpdate: function onClientPendingTaskUpdate(json) {
            try {
                var data = JSON.parse(json).data;
                this._handlePendingTaskUpdate(data);
            } catch(e) {
                console.error('onClientPendingTaskUpdate json error:' + e);
                console.error('onClientPendingTaskUpdate json: ' + json);
            }
        },

        onWebsocketConnected: function onWebsocketConnected() {
            this._pendingTasksTimeoutHandler();
            this._cancelPolling();
        },

        onWebsocketDisconnected: function onWebsocketDisconnected() {
            this._initiatePolling();
        },

        registerPendingTask: function registerPendingTask(taskNode) {
            this.excutingTasksHash[taskNode.taskId] = taskNode;
            this._initiatePolling(this.WebsocketHelperService.isConnected());
        },

        _handlePendingTaskUpdate: function _handlePendingTaskUpdate(task) {
            var taskNode = this.excutingTasksHash[task._id];
            var taskState = task.pending_task_state_cd;

            if (!taskNode) {
                return;
            }

            if (taskState === this.pendingTasksStatuses.started || taskState === this.pendingTasksStatuses.pending) {
                if(taskState === this.pendingTasksStatuses.started) {
                    //report the task's progress to whom it may concern
                    taskNode.taskDeferred.notify(task.pending_task_progress);
                }
            } else if (taskState === this.pendingTasksStatuses.finished) {
                taskNode.taskDeferred.resolve(task.pending_task_result);
                delete this.excutingTasksHash[taskNode.taskId];

            } else if (taskState === this.pendingTasksStatuses.canceled) {
                taskNode.taskDeferred.reject({message: task.pending_task_error_message, canceled: true});
                this._cancelPendingTask(taskNode);
            } else {
                //unknown or aborted
                taskNode.taskDeferred.reject(task.pending_task_error_message);
                this._cancelPendingTask(taskNode);
            }
        }
    });
}());
