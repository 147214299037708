(function () {
    'use strict';

    // @ngInject
    function IntercomServiceCtor($injector, $q, $window, Intercom, Upscope, AppConfigService, FeaturesService, UserService, moment, WorkspaceFileService, Enums, $log, FlowsBetaUserTypeService, DeviceService, FinanceAppService, DatadogRUMService, StatsigService) {
        this.$q = $q;
        this.Intercom = Intercom;
        this.AppConfigService = AppConfigService;
        this.FeaturesService = FeaturesService;
        this.moment = moment;
        this.DatadogRUMService = DatadogRUMService;
        this.WorkspaceFileService = WorkspaceFileService;
        this.isInAppBrowser = DeviceService.isInAppBrowser();
        this.Enums = Enums;
        this.$log = $log;
        this.FinanceAppService = FinanceAppService;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.StatsigService = StatsigService;
        this.isIntercomBooted = false;
        this.isIntercomBootedSecured = false;
        this.UserService = UserService;
        this.finBotAuthToken = null;

        this.pricing_levels = {
            starter: {feature_level: "Starter", service_level: "Standard"},
            essentials: {feature_level: "Essentials", service_level: "Standard"},
            premium: {feature_level: "Premium", service_level: "Priority"},
            legacy: {feature_level: "Essentials*", service_level: "Standard"},
            lifetime: {feature_level: "Premium", service_level: "Standard"}

        };

        this.plan_mapping = {
            "starter_monthly_2023": this.pricing_levels.starter,
            "starter_annual": this.pricing_levels.starter,
            "essentials_monthly": this.pricing_levels.essentials,
            "essentials_annual": this.pricing_levels.essentials,
            "premium_monthly": this.pricing_levels.premium,
            "premium_annual": this.pricing_levels.premium,
            // Legacy
            "starter_monthly": this.pricing_levels.legacy,
            "monthly_basic": this.pricing_levels.legacy,
            "monthly": this.pricing_levels.legacy,
            "unlimited_monthly": this.pricing_levels.legacy,
            "annual": this.pricing_levels.legacy,
            "unlimited_annual": this.pricing_levels.legacy,
            "lifetime": this.pricing_levels.lifetime,
            "free": this.pricing_levels.legacy,
            "manual": this.pricing_levels.legacy
        };
    }

    Services.IntercomService = Class({

        constructor: IntercomServiceCtor,

        init: function init(user) {
            if (!this.isInAppBrowser) {
                this.user = user;
                this._constructListeners();
            }
        },

        identify: function identify(extraArgs, force) {
            if (!this.isInAppBrowser && (this.shouldUseIntercom(this.user, force))) {
                var bootSettings = {
                    app_id: this.AppConfigService.appConfiguration().intercom_app_id,
                    widget: {
                        activator: "#hb-intercom"
                    }
                };
                if (this.user && this.user._id && this.user.intercom_hmac) {
                    bootSettings['user_id'] = this.user._id;
                    bootSettings['user_hash'] = this.user.intercom_hmac;
                }
                this._boot(this.user, bootSettings, extraArgs);
            }
        },

        shutdown: function shutdown(force) {
            if (this.shouldUseIntercom(this.user, force)) {
                this.Intercom.then(function (intercom) {
                    this.isIntercomBooted = false;
                    this.isIntercomBootedSecured = false;
                    // this should hide the messenger, I have to admit that it doesnt do anything on dev machine
                    intercom('hide');

                    // Here’s how to shutdown Intercom: https://www.intercom.com/help/en/articles/16845-how-do-i-end-a-session
                    // I have to admit that it doesnt do anything on dev machine
                    intercom('shutdown');
                }.bind(this));
            }
        },

        _isInLoginPage: function _isInLoginPage() {
            return (document.location.pathname === '/app/login');
        },

        _isInSignupPage: function _isInSignupPage() {
            return (document.location.pathname === '/app/signup');
        },

        shouldUseIntercom: function shouldUseIntercom(user, force) {
            if (user && user.is_hb_admin) {
                return false;
            }

            if (force) {
                return true;
            }

            if (this.isInAppBrowser) {
                return false;
            }

            return this._isInLoginPage() || this._isInSignupPage() || (user && user.isVendor());
        },

        _boot: function _boot(user, bootSettings, extraArgs) {
            var intercomSettings = {};

            var userData = user && !user.isEmpty() ? this._getUserAttributes(user) : {};
            angular.extend(intercomSettings, userData, extraArgs);

            this._getAsyncUserAttributes(user).then(function (asyncAttrs) {
                angular.extend(intercomSettings, asyncAttrs);
                return this.Intercom;
            }.bind(this)).then(function (intercom) {
                var bootSecuredReady = !this.isIntercomBootedSecured && bootSettings && !!bootSettings['user_hash'];
                if (!this.isIntercomBooted || bootSecuredReady) {
                    this.isIntercomBooted = true;
                    this.isIntercomBootedSecured = bootSecuredReady;
                    angular.extend(intercomSettings, bootSettings);
                    intercom('boot', intercomSettings);
                } else {
                    intercom('update', intercomSettings);
                }
            }.bind(this));
        },

        _getAsyncUserAttributes: function _getAsyncUserAttributes(user) {
            var attrs = {
                'has_finance_app': false,
                'use_new_elements': false,
                'use_wallets': false,
                'push_card_on_file_first_gate': false,
                'has_react_scheduler': false
            };

            var isFinanceEnabledPromise = this.FinanceAppService.isFinanceAppEnabled(user);
            var isNewStripeElementsPromise = this.StatsigService.isGateEnabled('stripe_elements');
            var isNewMobileWalletPromise = this.StatsigService.isGateEnabled('mobile_wallets');
            var isPushCardOnFileFirstPromise = this.StatsigService.isGateEnabled('push_card_on_file_first_gate');
            var isReactSchedulerEnabledPromise = this.StatsigService.isGateEnabled('ng2react-session-config-form');

            var finBotAuthTokenPromise = Promise.resolve({token: null});

            if (!this.finBotAuthToken && this.UserService.isLoggedIn() && !window.location.href.match(/login/)) {
                finBotAuthTokenPromise = this.UserService.getFinBotAuthToken();
            }


            return this.$q.all([isFinanceEnabledPromise, isNewStripeElementsPromise, isNewMobileWalletPromise, isPushCardOnFileFirstPromise, isReactSchedulerEnabledPromise, finBotAuthTokenPromise]).then(function (results) {
                attrs['has_finance_app'] = results[0];
                attrs['use_new_elements'] = results[1];
                attrs['use_wallets'] = results[2];
                attrs['push_card_on_file_first_gate'] = results[3];
                attrs['has_react_scheduler'] = results[4];

                var token = this.finBotAuthToken || (results[5] && results[5].token);

                if (token) {
                    attrs['fin_bot_auth_token'] = token;
                    this.finBotAuthToken = token; // Update the cache only if using a new token
                }
              
                return attrs;
            }.bind(this)).catch(function () {
                return attrs;
            }.bind(this));
        },

        _getUserAttributes: function _getUserAttributes(user) {
            var args = {
                user_id: user._id,
                email: user.email,
                fullName: user.full_name,
                firstName: user.first_name,
                phone: user.phone_number,
                lastName: user.last_name,
                name: user.full_name,
                user_type: user.system_user_type,
                job_title: user.job_title,
                user_job_title: user.user_type_name,
                currency: (user.company && user.company.country === 'CA') ? 'CAD' : 'USD',
                user_hb_system_type: user.hb_user_type,
                is_subscribed: user.companyHasSubscription(),
                first_login: this._asDate(user.first_login),
                last_login: this._asDate(user.last_login_time),
                bank_information_entered: !!user.myBankAccount(),
                gmail_status: user.google_integrations && user.google_integrations.length ? 'active' : 'inactive',
                first_payable_file_sent: !!user.last_file_sent_time,
                cancellation_reason: '',
                is_chrome_extension_installed: !!user.first_time_on_chrome_extension,
                intent: user.user_intent,
                utm_source: user.attribution && user.attribution.user_utm_params && user.attribution.user_utm_params.utm_source,
                utm_campaign: user.attribution && user.attribution.user_utm_params && user.attribution.user_utm_params.utm_campaign,
                utm_medium: user.attribution && user.attribution.user_utm_params && user.attribution.user_utm_params.utm_medium,
                'Is Password Exposed': user.is_password_exposed,
                'Is Email Deliverable': user.is_email_deliverable,
                'Flows': !!user.isFlagEnabled('enable_flow_flag'),
                'has_scheduler_block': true,
                n8n_automations: user.isN8nEnabled(),
                automations_mode: user.automationsMode(),
            };

            if (user.is_hb_admin) {
                args.user_is_hb_admin = true;
            }

            if (user.isPro()){
                args.hb_pro = true;
            }

            if (user.activated_on) {
                args.activated_date_from_product = this._asDate(user.activated_on);
            }

            if (angular.isNumber(user.bucket_number)) {
                args.bucket_number = user.bucket_number;
            }

            if (user.isVendor() && user.company) {
                args.company_id = user.company_id;
                args.user_company_type = user.company.company_type_name;
                args.user_company_name = user.company.company_name;
                args.currency = user.company.currency;
                args.user_company_team_members_count = user.company.team_members && user.company.team_members.length;
                args.user_company_email = user.company.email;
                args.user_company_state = user.company.state;
                args.user_company_city = user.company.city;
                args.has_multi_brands = (user.account && !!user.account.has_multiple_brands)
                args.flows_onboarding = this.FlowsBetaUserTypeService.hasOnlyFlows;
                args.has_pictime_integration = !!user.company.has_pictime_integration;

                if (user.company.owner) {
                    args.user_company_owner_email = user.company.owner.email;
                    args.user_company_owner_id = user.company.owner._id;
                }
            }

            if (!user.isDefaultProfileImage()) {
                args.avatar = {
                    "type": "avatar",
                    "image_url": user.profile_image_url
                };
            }

            if (user.account && user.account.subscription && user.account.subscription.plan_name) {
                if (this.plan_mapping[user.account.subscription.plan_code]) {
                    args.plan_name = user.account.subscription.plan_name;
                    args.feature_level = this.plan_mapping[user.account.subscription.plan_code].feature_level;
                    args.service_level = this.plan_mapping[user.account.subscription.plan_code].service_level;
                } else {
                    this.DatadogRUMService.addError(new Error('Missing intercom plan mapping'), {plan_code: user.account.subscription.plan_code});
                }
            }

            if (user.account && user.account.trial) {
                args.trial_duration = user.account.trial.duration;
                args.trial_start_date = this._asDate(user.account.trial.start_date);
                args.trial_days_to_end = user.account.trial.days_to_end;
            }

            return args;
        },

        _asDate: function _asDate(date) {
            return this.moment(date).format('MM/DD/YY');
        },

        _constructListeners: function _constructListeners() {
            this.user.on(this.Enums.PubSubTriggers.firstPayableFileSent, this.identify.bind(this));

            this.user.on(this.Enums.PubSubTriggers.membershipCanceled, function onMembershipCanceled(data) {
                this.$log.debug('[tracking: intercom] onMembershipCanceled');
                this.identify({cancellation_reason: data.reason});
            }.bind(this));

            this.user.on(this.Enums.PubSubTriggers.otamMigratedToFlows, this.identify.bind(this));
        }
    });
}());
