(function () {
    "use strict";

    // @ngInject
    function InvoiceHubServiceCtor(StatsigService, Enums, UsersManager, DeviceService) {

        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.DeviceService = DeviceService;

        this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
    }

    Services.InvoiceHubService = Class(() => {

        return {
            constructor: InvoiceHubServiceCtor,

            isInvoiceHubVisible() {
                return new Promise((resolve, reject) => {
                    resolve(false); // should clean up further usage, leaving this for now in-case we'll bring it back
                });
            }
        };
    });
}());

