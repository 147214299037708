(function () {
    'use strict';

    class FlowsOnboardingController extends Controllers.BaseControllerES6 {
        
        // @ngInject
        constructor(
            $scope,
            $injector,
            $timeout,
            $translate,
            AnalyticsService,
            DeviceService,
            OnboardingService,
            SetupGuideService,
            UiPersistenceService,
            $,
            _,
            UsersManager,
            Enums,
            IntentService,
            AbTestService,
            StatsigService,
            IntercomService
        ) {
            super($scope, $injector);
            this.__objectType = 'FlowsOnboardingController';

            this.$ = $;
            this.$timeout = $timeout;
            this.$scope = $scope;
            this.$translate = $translate;
            this._ = _;
            this.DeviceService = DeviceService;
            this.OnboardingService = OnboardingService;
            this.SetupGuideService = SetupGuideService;
            this.AbTestService = AbTestService;
            this.AnalyticsService = AnalyticsService;
            this.UsersManager = UsersManager;
            this.Enums = Enums;
            this.UiPersistenceService = UiPersistenceService;
            this.StatsigService = StatsigService;
            this.IntercomService = IntercomService;

            this.isMobile = this.DeviceService.nxSmallBreakpoint();
            this.isLoading = true;
            this.isMarkedComplete = false;
            this.IntentService = IntentService;
            
            this.user = this.UsersManager.getCurrUser();

            this.isIntentOnboardingCompleted = this.UiPersistenceService.getUiPersistence(
                this.UiPersistenceService.keys.intentOnboardingIsCompleted, { value: false }
            ).value;

            const variationToSequenceMap = {
                contract_onboarding: {
                    [this.Enums.flowsOnboardingWizardSteps.branding]: 'FLOWS_ONBOARDING.CONTRACT_ONBOARDING.STEP_BRANDING',
                    [this.Enums.flowsOnboardingWizardSteps.templates]: 'FLOWS_ONBOARDING.CONTRACT_ONBOARDING.STEP_TEMPLATES',
                    [this.Enums.flowsOnboardingWizardSteps.selected]: 'FLOWS_ONBOARDING.CONTRACT_ONBOARDING.STEP_FINAL_SCREEN'
                },
                skip_template: {
                    [this.Enums.flowsOnboardingWizardSteps.branding]: 'FLOWS_ONBOARDING.STEP_BRANDING_ABC',
                    [this.Enums.flowsOnboardingWizardSteps.services]: 'FLOWS_ONBOARDING.STEP_SERVICES_ABC_VARIANT_SKIP_TEMPLATE',
                    [this.Enums.flowsOnboardingWizardSteps.selected]: 'FLOWS_ONBOARDING.STEP_FINAL_SCREEN_ABC'
                },
                legacy_like: {
                    [this.Enums.flowsOnboardingWizardSteps.branding]: 'FLOWS_ONBOARDING.STEP_BRANDING_ABC',
                    [this.Enums.flowsOnboardingWizardSteps.legacyServices]: 'FLOWS_ONBOARDING.STEP_SERVICES_ABC_VARIANT_LEGACY_LIKE',
                    [this.Enums.flowsOnboardingWizardSteps.selected]: 'FLOWS_ONBOARDING.STEP_FINAL_SCREEN_ABC_LEGACY_LIKE'
                }
            }

            const defaultSequence = {
                [this.Enums.flowsOnboardingWizardSteps.branding]: 'FLOWS_ONBOARDING.STEP_BRANDING',
                [this.Enums.flowsOnboardingWizardSteps.services]: 'FLOWS_ONBOARDING.STEP_SERVICES',
                [this.Enums.flowsOnboardingWizardSteps.templates]: 'FLOWS_ONBOARDING.STEP_TEMPLATES',
                [this.Enums.flowsOnboardingWizardSteps.selected]: 'FLOWS_ONBOARDING.STEP_FINAL_SCREEN'
            }

            const type = this.getOnboardingSequenceType();
            this.stepTranslationMap = variationToSequenceMap[type] || defaultSequence;

            $scope.$watch('$ctrl.OnboardingService.onboardingSequence',
                (newSequence) => {
                    if (newSequence) {
                        this.currentStep = newSequence.current_step || {};

                        this.allSteps = newSequence.steps;

                        this.wizardSteps = this.allSteps.filter(step => step.phase === 'wizard');

                        this.listSteps = this.allSteps.filter(step => step.phase === 'list');

                        const wizardStepsAreComplete = this._.every(
                            this.wizardSteps, wizStep => wizStep.status === 'complete'
                        );
                        
                        this.allSteps = this.wizardSteps.concat(this.listSteps);

                        this.shouldSeeList = wizardStepsAreComplete || this.isIntentOnboardingCompleted;
                        this.OnboardingService.setOnboardingPhaseOneComplete(this.shouldSeeList);
                        this.firstPendingStep = this.getFirstPendingStep(this.allSteps);
                        this.isSequenceComplete = newSequence.is_sequence_complete;

                        this.shouldShowBasicSetupGuideSteps = this.shouldSeeList && !this.isMobile;

                        this.IntercomService.identify({jan_jumpstart_setup_guide: newSequence.is_jan_jumpstart_campaign}, true);

                        this.shouldShowReactSetupGuide = !newSequence.should_hide_setup_guide_from_old_users &&
                            ((this.shouldShowBasicSetupGuideSteps && !this.isSequenceComplete) ||
                                (this.shouldSeeList && !this.isMobile && newSequence.should_show_jan_jumpstart_setup_guide));

                        this.initializeMainView();
                    }
                }
            );
            

        }

        initializeMainView() {
            const $slideTarget = this.$('.js-flows-onboarding-slide-target');
            if (this.isCollapsed) {
                $slideTarget.hide();
            }
            this.$timeout(() => {
                this.isLoading = false;
            }, 1000);
        }

        handleCollapse() {
            if (this.isMobile) {
                return;
            }

            this.isCollapsed = !this.isCollapsed;
            var $slideTarget = this.$('.js-flows-onboarding-slide-target');

            if (this.isCollapsed) {
                $slideTarget.slideUp(500);
            } else {
                this.currentStep = this.getFirstPendingStep(this.allSteps);
                $slideTarget.slideDown(500);
            }

            this.SetupGuideService.setPersistenceHomeOnboardingCard({ isCollapsed: this.isCollapsed });
        }

        slideUpOnboardingContainer() {
            var $slideTarget = this.$('.js-flows-onboarding-container');

            this.$timeout(() => {
                $slideTarget.slideUp(500);
            }, 1000);
        }

        handleMarkedComplete() {
            this.isMarkedComplete = true;
            this.slideUpOnboardingContainer();
            this.SetupGuideService.setPersistenceHomeOnboardingCard({ hasMarkedComplete: true });
            this.isCollapsed = true;
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.setup_guide_completed, {action: 'click', completed_by: 'manually' });
            this.AnalyticsService.reportIterableEvent('this.AnalyticsService.analytics_events.setup_guide_completed', { completed_by: 'manually' }, this.user);
        }

        getFirstPendingStep(steps) {
            return steps.find(step => step.status === 'pending');
        }

        completeMwebWizard() {
            this.isMarkedComplete = true;
            this.slideUpOnboardingContainer();
            this.SetupGuideService.setPersistenceHomeOnboardingCard({ hasClickedCompleteMwebWizard: true });
        }

        getOnboardingSequenceType() {
            if (this.IntentService.isInSpecificIntentGroup('contracts')) {
                return 'contract_onboarding'
            }
            const t1ActivationABCVariant = this.OnboardingService.getT1ActivationABCVariant();
            return t1ActivationABCVariant;
        }
    }

    Components.FlowsOnboarding = {
        bindings: {
            isCollapsed: '=',
            isShowIntentOnboarding: '<',
            intentOnboardingType: '<',
            onboardingType: '<',
            onboardingAbTest: '<'
        },
        controller: FlowsOnboardingController,
        name: 'hbFlowsOnboarding',
        templateUrl: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding.html',
        bindToController: true,
    };

}());



