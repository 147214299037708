(function () {
    'use strict';

    // @ngInject
    function NewAutomationsControllerCtor(
        $scope,
        $injector
    ) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'NewAutomationsController';
    }

    Controllers.NewAutomationsController = Class(Controllers.BaseController, {
        constructor: NewAutomationsControllerCtor,
    });
}());
