(function () {
    'use strict';

    // @ngInject
    function SecondLevelNavigationBookkeepingControllerCtor($scope, $injector, $stateParams, $state, AnalyticsService, AppStates, UsersManager, moment, FeaturesService, DeviceService, FinanceAppService,
                                                            StatsigService, Enums, $q, InvoiceHubService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'SecondLevelNavigationBookkeepingController';

        this.menuItemsViewModel = [];
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.moment = moment;

        this.user = UsersManager.getCurrUser();
        this.Enums = Enums;
        this.StatsigService = StatsigService;
        this.$q = $q;
        this.InvoiceHubService = InvoiceHubService;
        this.AnalyticsService = AnalyticsService;

        // Prepare the view model
        this.title = 'Finance';

        // Default navigation menu items
        this.navigationMenuItems = [];

        Promise.all([
            FinanceAppService.isFinanceAppEnabled(this.user),
            this.StatsigService.isGateEnabled('finance_app_overview'),
            this.StatsigService.isGateEnabled('finance_legacy_expenses'),
            this.StatsigService.isGateEnabled('finance_tax_hub'),
            this.StatsigService.isGateEnabled('finance_profit_and_loss'),
            this.InvoiceHubService.isInvoiceHubVisible()
        ]).then(function(results) {
            var isBalanceEnabled = results[0];
            var isOverviewEnabled = results[1];
            var isExpensesEnabled = results[2];
            var isTaxHubEnabled = results[3];
            var isFinancePNLEnabled = results[4];
            var isInvoiceHubVisible = results[5];
            var isCapitalEnabled = this.user.isDemo() || this.user.isFeatureActive(FeaturesService.rolloutFeatures.capitalJaris);

            if (isOverviewEnabled) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Overview',
                    stateToGoto: AppStates.root_core_navigation_finance_overview
                });
            }

            if (isBalanceEnabled) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Balance',
                    stateToGoto: AppStates.root_core_navigation_finance
                });
            }

            if(isInvoiceHubVisible) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Invoices',
                    stateToGoto: AppStates.root_core_navigation_invoices
                });
            }

            this.navigationMenuItems.push({
                linkTitleKey: 'Payments',
                stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_reports_payments : AppStates.root_core_navigation_reports_payments
            });

            if (this.user.hasCompanySuperAdminPermissions() && isExpensesEnabled) {
                if(isExpensesEnabled) {
                    this.navigationMenuItems.push({
                        linkTitleKey: 'Expenses',
                        stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_expenses : AppStates.root_core_navigation_expenses
                    });
                }
            }

            if(isCapitalEnabled) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Capital',
                    stateToGoto: AppStates.root_core_navigation_capital
                });
            }

            if(this.user.hasCompanySuperAdminPermissions() && isFinancePNLEnabled) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Profit & Loss',
                    stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_reports_profit_loss : AppStates.root_core_navigation_profit_loss
                });
            }

            if (this.user.hasCompanySuperAdminPermissions()) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Quickbooks',
                    stateToGoto: this.user.isBookkeeperMode() ? AppStates.root_core_navigation_bookkeeping_settings_company_quickbooks : AppStates.root_core_navigation_settings_companySettings_quickbooks
                });
            }

            if (this.user.hasCompanySuperAdminPermissions() && isTaxHubEnabled) {
                this.navigationMenuItems.push({
                    linkTitleKey: 'Tax Hub',
                    stateToGoto: AppStates.root_core_navigation_finance_taxes
                });
            }
        }.bind(this));
    }

    Controllers.SecondLevelNavigationBookkeepingController = Class(Controllers.BaseController, {
        constructor: SecondLevelNavigationBookkeepingControllerCtor,

        onMenuItemClicked: function onMenuItemClicked(menuItem) {
            this.AnalyticsService.trackClick(this, 'finance navigation', {item: menuItem.linkTitleKey});
            this.goToState(menuItem.stateToGoto, {source: 'finance_nav'});
        }
    });
}());