/**
 * The hb-contact-form-settings component
 *
 * This component shows the settings panel of the contact form sidebar
 *
 */


(function () {
    'use strict';

    class ContactFormSettingsComponentController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $translate, $timeout, AnalyticsService, TemplatesViewService,
                    FeaturesService, PopupMessageService, ContactFormService, ModalService, _, AppStates, $state)  {
            super($scope, $injector, $translate, $timeout);
            this.__objectType = 'ContactFormSettingsComponentController';
            this.$timeout = $timeout;
            this.PopupMessageService = PopupMessageService;
            this.AnalyticsService = AnalyticsService;
            this.ContactFormService = ContactFormService;
            this.TemplatesViewService = TemplatesViewService;
            this.FeaturesService = FeaturesService;
            this.ModalService = ModalService;
            this._ = _;
            this.AppStates = AppStates;
            this.$state = $state;
            this.redirectTypes = [
                {
                    type: 'default',
                    label: $translate.instant('CONTACT_FORM.LABELS._DEFAULT_THANK_YOU_')
                },
                {
                    type: 'custom_url',
                    label: $translate.instant('CONTACT_FORM.LABELS._THANK_YOU_URL_')
                }
            ];

            this.projectTypes = angular.copy(this.company.sortedProjectTypes());
            this.projectTypes.unshift({label: $translate.instant('FREQUENT_BUTTONS._NONE_'), _id: null});

            this.linksIsCopied = false;
            this.copyLinkTooltip = "Copy link";
            this.showThankYouPopover = false;
            this.showAutomationPopover = false;
            this.showInstantPaymentPopover = false;
            this.collapsedAvatarsTextPattern = '+[NUM] Team members';

            this.register(this.contactFormModel, 'success', this.gotContactForm.bind(this));
            this._updateInquiryRecipientsList();
            this._getAutomationProjectType();
            $scope.$on('$destroy', function () {
                if (this.onDestroy && angular.isFunction(this.onDestroy)) {
                    this.onDestroy();
                }
            }.bind(this));

            this.modelTypeDisplayText = this.contactFormModel.isGiftCard() ? 'Gift Card' : 'Contact Form';
        }

        gotContactForm() {
            this._updateInquiryRecipientsList();
            this._getAutomationProjectType();
        }

        _getAutomationProjectType() {
            this.projectType = this.getSelectedProjectType()
        }

        _updateInquiryRecipientsList() {
            this.teamMembers = this.company.getAllCompanyMembers();
            this.projectOwner = this.getProjectOwner();
            if (this.projectOwner) {
                this.inquiryRecipients = this.getNonProjectOwnerRecipients();
            } else {
                //repair missing project owner
                this.inquiryRecipients = [];
                this.company.setUserAsInquiryRecipient(null, this.contactFormModel, true).then(this.gotContactForm.bind(this));
            }
        }

        isInvalidRedirectUrl() {
            return (this.redirectUrl && this.checkUrlInvalid) || this.redirectUrlIsEmpty;
        }

        previewThankYouMessage(){
            if (this.ContactFormService.isFormAndSettingsValid(this.TemplatesViewService.currentTemplate)) {
                this.gettingPreviewCode = true;
                this.ContactFormService.thankYouPreview = true;
                this.ContactFormService.openContactFormPreview(this.TemplatesViewService.currentTemplate).then(function(){this.gettingPreviewCode = false}.bind(this));
            }
        }

        linkCopied() {
            var elem = document.getElementById('copyDirectLinkButton');
            this.linksIsCopied = true;
            this.copyLinkTooltip = "Link copied!";

            this.$timeout(() => {
                elem._tippy.hide();
            }, 5000);

            this.$timeout(() => {
                this.linksIsCopied = false;
                this.copyLinkTooltip = "Copy link";
            }, 5500);
        }

        shouldSelectInquiryRecipient() {
            let teamMembers = this.company.getOtherCompanyMembers();
            return this.currentUser.hasCompanyModeratorPermissions() &&
                !!teamMembers && teamMembers.length > 0;
        }

        isNewAutomations(){
            return this.currentUser.isNewAutomationsEnabled();
        }

        getProjectOwner() {
            let projectOwner = undefined;
            if (this.contactFormModel.vendor_id_for_inquiries !== 'undefined' && this.contactFormModel.vendor_id_for_inquiries !== null) {
                projectOwner = this.teamMembers.find(teamMember => teamMember._id === this.contactFormModel.vendor_id_for_inquiries);
            }
            return projectOwner;
        }

        getNonProjectOwnerRecipients() {
            let memberIds = this.contactFormModel.getMemberIds();
            memberIds = memberIds.filter(memberId => memberId !== this.projectOwner._id);
            return this.teamMembers.filter(teamMember => memberIds.includes(teamMember._id));
        }

        openMembersModal() {
            this.ModalService.openContactFormMembersModal(this.contactFormModel);
        }

        goToWorkflows() {
            if (this.isNewAutomations()) {
                this.AnalyticsService.trackClick(this, 'go_to_automations_page', {source: 'contact form', automation_version: 'v1'});
                this.goToState(this.AppStates.root_core_navigation_automations);
            } else {
                this.AnalyticsService.trackClick(this, 'go_to_automations_page', {source: 'contact form', automation_version: 'v2'});
                this.goToState(this.AppStates.root_core_navigation_newautomations);
            }

        }

        getSelectedProjectType() {
            if (this.contactFormModel.contact_form_settings.project_type) {
                let projectType = this._.find(this.projectTypes, (type) => type._id === this.contactFormModel.contact_form_settings.project_type);
                if (projectType) {
                    return projectType;
                }
            }
            return this.projectTypes[0]; //None
        }

        shouldShowRedirectOption() {
            return !this.contactFormModel.isGiftCard();
        }

		shouldShowAutomateOption() {
            return !this.contactFormModel.isGiftCard();
        }

		shouldShowInstantPaymentToggle() {
            return this.contactFormModel.isGiftCard() &&
                this.ContactFormService.shouldShowInstantPayment &&
                this.company.company_url_prefixes &&
                this.company.company_url_prefixes.length > 0;
        }
    }

    Components.ContactFormSettings = {
        bindings: {
            currentUser: '<',
            company: '<',
            redirectType: '=',
            setRedirectType: '&',
            contactFormType: '<',
            contactFormId: '<',
            redirectUrl: '=',
            redirectUrlIsEmpty: '<',
            isFocusOnRedirectUrlText: '<',
            setRedirectUrl: '&',
            setAutomationProjectType: '&',
            beforeSelectAutomationProjectType: '&',
            checkUrlInvalid: '<',
            redirectUrlInProgress: '<',
            tradeShowUrl: '<',
            openTradeShowTab: '&',
            contactFormModel: '<',
            onDestroy: '&',
        },
        controller: ContactFormSettingsComponentController,
        templateUrl: 'angular/app/modules/core/features/contact_form/aside_menu_content/contact_form_settings/contact_form_settings.html',
    };

}());
