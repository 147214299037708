/**
 * Created by inonstelman on 12/31/14.
 */

(function () {
    "use strict";

    var _defaultAppState = {
        leftPanelOpen: false,
        leftPanelEventOpen: false,
        leftPanelSettingsOpen: false,
        miniEventOpen: false,
        inNetworkRoute: false,
        isFileRouteAndClientMode: false,
        topNavOnboardingOpen: false,
        asideDrawerOpen: false,
        inFilePreviewMode: false,
        showLegacyTemplatesBanner: false
    };

    var _dataMap = {

        appState: null,
        appConfiguration: null, // initialized in constructor
        filePaymentsState: {},
        filePackageState: {},
        dashboardState: {
            workspacesLoading: false,
            filesLoading: false
        },
        eventLoadingState: {
            workspacesEventId : null,
            isWorkspaceTabSelected: false
        },
        reportsState: {
            analytics: null,
            payments: null,
            events: null,
            fees: null
        },

        webNotifications: {
            wasFetched: false
        },

        tasks: {
            wasCountInitiallyFetched: false,
            wasSettingsInitiallyFetched: false
        },

        receiptState :{
            workspaceFile : null,
            paymentId : null
        },

        questionnaireSubmittedState: {
            workspaceFile: null
        }
    };

    var _isMalkut = null;

    // @ngInject
    function AppConfigCtor(Gon, _, $window, Constants, $, DatadogLOGSService) {
        _dataMap.appConfiguration = {};
        _.extend(_dataMap.appConfiguration, Gon);

        _dataMap.stripe_key = null;
        _dataMap.appState = _defaultAppState;

        this.$window = $window;
        this.$ = $;
        this.Constants = Constants;

        this.appNeedsRefresh = false;
        this.DatadogLOGSService = DatadogLOGSService;
    }


    Services.AppConfig = Class(function () {

        return {

            constructor: AppConfigCtor,

            appState: function appState() {
                return _dataMap.appState;
            },

            clearAppState: function clearAppState() {
                _dataMap.appState = _defaultAppState;
            },

            appConfiguration: function appConfiguration() {
                return _dataMap.appConfiguration;
            },

            filePaymentState: function filePaymentState(){
                return _dataMap.filePaymentsState;
            },

            resetFilePaymentsState: function resetFilePaymentsState(){

                for (var key in _dataMap.filePaymentsState){
                    if(_dataMap.filePaymentsState.hasOwnProperty(key)) {
                        delete _dataMap.filePaymentsState[key];
                    }
                }
            },

            filePackageState: function filePackageState(){
                return _dataMap.filePackageState;
            },

            resetFilePackageState: function resetFilePackageState(){

                for (var key in _dataMap.filePackageState){
                    if(_dataMap.filePackageState.hasOwnProperty(key)) {
                        delete _dataMap.filePackageState[key];
                    }
                }
            },

            reportsState: function reportsState(){
                return _dataMap.reportsState;
            },

            resetReportsState: function resetReportsState(){
                for (var key in _dataMap.reportsState){
                    if(_dataMap.reportsState.hasOwnProperty(key)){
                        delete _dataMap.reportsState[key];
                    }
                }
            },

            receiptState: function receiptState(){
                return _dataMap.receiptState;
            },

            resetReceiptState: function resetReceiptState(){
                for (var key in _dataMap.receiptState){
                    if(_dataMap.receiptState.hasOwnProperty(key)) {
                        delete _dataMap.receiptState[key];
                    }
                }
            },

            questionnaireSubmittedState: function questionnaireSubmittedState() {
                return _dataMap.questionnaireSubmittedState;
            },

            resetQuestionnaireSubmittedState: function resetQuestionnaireSubmittedState() {
                for (var key in _dataMap.questionnaireSubmittedState) {
                    if (_dataMap.questionnaireSubmittedState.hasOwnProperty(key)) {
                        delete _dataMap.questionnaireSubmittedState[key];
                    }
                }
            },

            setStripeKey: function setStripeKey(key){
                _dataMap.appConfiguration.stripeKey = key;
            },

            setPlaidConfig: function setPlaidConfig(publicKey, env){
                _dataMap.appConfiguration.plaidPublicKey = publicKey;
                _dataMap.appConfiguration.plaidEnv = env;
            },

            dashboardState: function dashboardState(){
                return _dataMap.dashboardState;
            },

            getWorkspacesEventId: function getWorkspacesEventId(){
                return _dataMap.eventLoadingState.workspacesEventId;
            },

            setWorkspacesEventId: function setWorkspacesEventId(eventId){
                _dataMap.eventLoadingState.workspacesEventId = eventId;
            },

            doesNeedsRefresh: function doesNeedsRefresh() {
                return this.appNeedsRefresh;
            },

            checkIfNeedRefresh: function checkIfNeedRefresh(apiVersion, isForced, isReactVersion = false) {
                if (apiVersion && (parseInt(apiVersion) !== parseInt(_dataMap.appConfiguration.api_version))) {
                    if (isForced || ((apiVersion - _dataMap.appConfiguration.api_version) >= _dataMap.appConfiguration.api_version_force_reload_diff)) {
                        this.$window.location.reload();
                        this.DatadogLOGSService.logInfo('user was gracefully reloaded', {scope: 'angular', whatPublished: 'angular'});
                    } else {
                        // console.log('appNeedsRefresh = true');
                        this.appNeedsRefresh = true;
                    }
                } else if (isReactVersion) {
                    if (isForced) {
                        this.$window.location.reload();
                        this.DatadogLOGSService.logInfo('user was gracefully reloaded', {scope: 'angular', whatPublished: 'react'});
                    } else {
                        this.appNeedsRefresh = true;
                    }
                }
            },

            wasWebNotificationsFetched: function wasWebNotificationsFetched() {
                return _dataMap.webNotifications.wasCountInitiallyFetched;
            },

            setWebNotificationsFetched: function setWebNotificationsFetched(wasFetched = true) {
                _dataMap.webNotifications.wasCountInitiallyFetched = wasFetched;
            },

            wasWebNotificationsSettingsInitiallyFetched: function wasWebNotificationsSettingsInitiallyFetched() {
                return _dataMap.webNotifications.wasSettingsInitiallyFetched;
            },

            setWebNotificationsSettingsInitiallyFetched: function setWebNotificationsSettingsInitiallyFetched(wasFetched = true) {
                _dataMap.webNotifications.wasSettingsInitiallyFetched = wasFetched;
            },

            wasTasksCountInitiallyFetched: function wasTasksCountInitiallyFetched() {
                return _dataMap.tasks.wasCountInitiallyFetched;
            },

            setTasksCountInitiallyFetched: function setTasksCountInitiallyFetched(wasFetched = true) {
                _dataMap.tasks.wasCountInitiallyFetched = wasFetched;
            },

            getAppStyleType: function () {
                //this is taken from the measurements of the foundations grid system
                //http://foundation.zurb.com/docs/media-queries.html
                var windowWidthInEm = this.$window.innerWidth / parseFloat(this.$("body").css("font-size"));

                if(windowWidthInEm <= 40){
                    return this.Constants.AppStyleTypes.small;
                } else if ((windowWidthInEm > 40) && (windowWidthInEm <= 64)){
                    return this.Constants.AppStyleTypes.medium;
                } else if ((windowWidthInEm > 64) && (windowWidthInEm <= 90)){
                    return this.Constants.AppStyleTypes.large;
                } else if ((windowWidthInEm > 90) && (windowWidthInEm <= 120)){
                    return this.Constants.AppStyleTypes.xlarge;
                } else {
                    return this.Constants.AppStyleTypes.xxlarge;
                }
            },

            isMalkutServer: function isMalkutServer() {
                if (_isMalkut === null) {
                    _isMalkut = this.$window.location.hostname.indexOf('malkut') !== -1;
                }

                return _isMalkut;
            },

            isProduction: function isProduction() {
                return this.appConfiguration().environment === 'production';
            },

            isDevelopment: function isDevelopment() {
                return this.appConfiguration().environment === 'development';
            },

            isStaging: function isStaging() {
                return this.appConfiguration().environment === 'staging';
            },

            isE2E: function isE2E() {
                return this.appConfiguration().e2e_env;
            },

            isTest: function isTest() {
                return this.appConfiguration().environment === 'test';
            },

            hbUrl() {
                return this.appConfiguration().url;
            }
        };
    });

}());
