(function () {
    'use strict';

    // @ngInject
    Services.StatsigService = function StatsigService($window, Gon, $q, UsersManager, CompanyService, DeviceService, AppConfigService, Enums,
                                                      DatadogRUMService, DatadogLOGSService, NetworkConnectionService, ThirdPartyLoaderService, UiPersistenceService,
                                                      _) {
        const E2E_TIER = Gon.statsig_e2e_tier || 'e2e';
        const API_URL = Gon.statsig_api_url || 'https://statsigapi.net/v1';
        const isInAppBrowser = DeviceService.isInAppBrowser();
        const envTier = AppConfigService.isE2E() ? E2E_TIER : Gon.environment;
        const hasUser = user => user && user._id;

        let statsigUpdateUserPromiseReference = null;
        let statsigInitializationPromiseReference = null;
        const commonDatadogContext = {
            statsig_service_type: "Angular",
            hasStatsigOnWindow: !!$window.statsig
        }

        const reportError = (error, message, userId, extraContext = {}) => {
            const context = {
                statsig_error: message,
                userID: userId,
                network_status: {
                    navigatorOnLine: NetworkConnectionService.isBrowserOnline(),
                    isOfflineWithNetworkErrors: NetworkConnectionService.isOfflineWithNetworkErrors()
                }
            };
            angular.extend(context, commonDatadogContext, extraContext);
            DatadogRUMService.addError(error, context);
        };

        const getStatsigOptions = user => {
            const statsigOptions = {
                environment:
                    {
                        tier: envTier
                    },
                localMode: AppConfigService.isTest(),

                gateEvaluationCallback: (key, value) => {
                    DatadogRUMService.addFeatureFlagEvaluation(key, value);
                },

                initCompletionCallback: (initDurationMs, success, message) => {
                    if (!!success) {
                        const randomNum = Math.floor(Math.random() * 101);
                        const percentage = Gon.statsig_log_init_timeout_percentage;
                        if (randomNum <= percentage) {
                            DatadogLOGSService.logInfo('initCompletionCallback success', {statsig_init_duration_ms: initDurationMs});
                        }
                    } else {
                        reportError(
                            new Error(message ? message : 'unknown error'),
                            'initCompletionCallback error',
                            user && user._id,
                        );
                    }
                },
                initTimeoutMs: Gon.statsig_initialization_timeout,

                logLevel: AppConfigService.isDevelopment() ? 'WARN' : 'NONE',

                api: API_URL,
            };

            if (envTier === E2E_TIER) {
                // set long initTimeoutMs for e2e env. its practically sync load.
                statsigOptions.initTimeoutMs = 50000;
            }

            let stableIDFromUser = user && user.statsig_stable_id;
            if (stableIDFromUser) {
                statsigOptions.overrideStableID = stableIDFromUser;
            }

            return statsigOptions;
        };

        const getUserCustomAttributes = user => {
            const wasInvoiceSent = UiPersistenceService.getUiPersistence(UiPersistenceService.keys.wasInvoiceSent) && UiPersistenceService.getUiPersistence(UiPersistenceService.keys.wasInvoiceSent).value;
            const financeAccountStatus = user.account && user.account.finance_account_status;
            const companyOwner = user.company && user.company.owner;
            const userUtmParams = user.attribution && user.attribution.user_utm_params;
            const ownerUtmParams = companyOwner && companyOwner.attribution && companyOwner.attribution.user_utm_params;
            return {
                intent: user.user_intent,
                trial_start_date: user.getTrialStartDate(),
                intent_infra_variation: user.ab_tests && user.ab_tests.intent_infra,
                growth_2024_variation: user.ab_tests && user.ab_tests.growth_2024,
                company_id: user.company._id,
                company_type: user.company.company_type === "other" ? user.company.other_company_type : user.company.company_type,
                company_vertical: user.company.company_vertical,
                is_demo: user.isDemo(),
                is_activated: user.is_activated, // member who has a subscription and got paid at least once
                is_active: user.is_active, // member who was activated and sent any flow or file in tha last 30 days
                is_nev: CompanyService.isNev(user.company),
                is_subscribed: user.companyHasSubscription(),
                is_pro: user.isPro(),
                otam_group: user.account && user.account.otam_group,
                account_id: user.isMalkut() ? user._id : user.account_id,
                has_smart_files: user.isFlagEnabled('enable_flow_flag'),
                has_legacy_files: !user.isFlagEnabled('block_files_flag'),
                cio_variant: user.ab_tests && user.ab_tests.contract_intent_onboarding_v3,
                is_account_owner: user.is_account_owner,
                is_home_payments_poc_audience: user.ab_tests && user.ab_tests.home_payments_poc,
                was_invoice_sent: wasInvoiceSent,
                has_gmail_integration: user.isGoogleIntegrationActive('gmail'),
                is_multi_brand: user.hasMultipleCompanies(),
                has_bank_account: !!user.myBankAccount(),
                company_country: user.company.country,
                onboarding_start_device: user.onboarding_container && user.onboarding_container.onboarding_start_device,
                utm_source: (userUtmParams && userUtmParams.utm_source) || (ownerUtmParams && ownerUtmParams.utm_source),
                utm_campaign: (userUtmParams && userUtmParams.utm_campaign) || (ownerUtmParams && ownerUtmParams.utm_campaign),
                utm_medium: (userUtmParams && userUtmParams.utm_medium) || (ownerUtmParams && ownerUtmParams.utm_medium),
                subscription_start_date: user.getSubscriptionStartDate(),
                initial_referral_code: user.attribution && user.attribution.user_utm_params.utm_mbsy,
                finance_application_approved_ts: financeAccountStatus && financeAccountStatus.application_approved_ts,
                finance_application_awaiting_documents_ts: financeAccountStatus && financeAccountStatus.application_awaiting_documents_ts,
                finance_account_closed_ts: financeAccountStatus && financeAccountStatus.account_closed_ts,
                owner_onboarding_start_device: companyOwner && companyOwner.onboarding_container && companyOwner.onboarding_container.onboarding_start_device,
                is_pre_trial_collaborator: user.isAtwsPreTrialVendor()
            };
        };

        // this functionality is responsible for building the users custom attributes,
        // only after making sure it's fully fetched, and has all the updated attributes
        const buildUserCustomAttributes = (user) => {
            const defer = $q.defer();
            let userCustomAttributes = {};

            if(user.__isUserFullyLoaded){
                userCustomAttributes = getUserCustomAttributes(user);
                defer.resolve(userCustomAttributes);
            } else {
                UsersManager.forceFetchCurrUser().then(() => {
                    const fullUser = UsersManager.getCurrUser();
                    userCustomAttributes = getUserCustomAttributes(fullUser);
                    defer.resolve(userCustomAttributes);
                }).catch((err) => {
                    if (err && err.data && err.data.error_type === 'HBUnauthorizedError'){
                        defer.resolve(userCustomAttributes);
                    } else {
                        reportError(err, "statsig- error in force fetching the user when user isn't fully loaded", user && user._id, {isLoggedIn: UsersManager.isLoggedIn()});
                        defer.reject(err);
                    }
                });
            }

            return defer.promise;
        };

        const getConfigCustomAttributes = () => {
            const customAttributes = {};
            if (AppConfigService.isDevelopment()) {
                customAttributes.local_user = Gon.local_user;
            } else if (AppConfigService.isStaging()) {
                customAttributes.staging_env_name = Gon.staging_env_name;
            }

            customAttributes.is_in_app_browser = isInAppBrowser
            return customAttributes;
        };

        // builds the statsig user custom attributes, which consists of configuration attributes,
        // and attributes that are user related
        const buildCustomAttributes = user => {
            const defer = $q.defer();
            let customAttributes = getConfigCustomAttributes();
            
            try {
                if (hasUser(user)){
                    buildUserCustomAttributes(user).then((userCustomAttributes) => {
                        Object.assign(customAttributes, userCustomAttributes);
                        defer.resolve(customAttributes);
                    }).catch((err) => {
                        defer.reject(err);
                    });
                } else {
                    defer.resolve(customAttributes);
                }
            } catch (err) {
                reportError(err, "statsig- error building custom attributes", user && user._id);
                defer.reject(err);
            }

            return defer.promise;
        };

        const buildStatsigUser = user => {
            const defer = $q.defer();

            let statsigUser = { userID: undefined };

            try {
                buildCustomAttributes(user).then((custom) => {
                    statsigUser.custom = custom;

                    // replace undefined attributes for null since they're not saved in statsig,
                    // which messes the user comparison
                    Object.keys(statsigUser.custom).forEach(customKey => {
                        if(statsigUser.custom[customKey] === undefined){
                            statsigUser.custom[customKey] = null;
                        }
                    });
                    
                    if (hasUser(user)) {
                        statsigUser.userID = user._id;
                        statsigUser.email = user.email;
                        statsigUser.customIDs = { account_id: user.account_id, company_id: user.company._id };
                        let stableIDFromUser = user.statsig_stable_id;
                        if (stableIDFromUser) {
                            statsigUser.customIDs.stableID = stableIDFromUser;
                        }
                    }
                    defer.resolve(statsigUser);
                }).catch((err) => {
                    defer.reject(err);
                });
            } catch (err) {
                reportError(err, "statsig- error building statsigUser", user && user._id);
                defer.reject(err);
            }

            return defer.promise;
        };

        const initializeStatsigUser = (statsigUser, user) => {
            const defer = $q.defer();

            if (statsigInitializationPromiseReference) {
                DatadogLOGSService.logError('initializeStatsigUser() was called while initializing', {statsig_error: 'multiple initializations', userID: user._id});
            }

            // Keep a ref to the promise to prevent multiple initialization calls
            statsigInitializationPromiseReference = defer.promise;

            const statsigOptions = getStatsigOptions(user);

            ThirdPartyLoaderService.loadUrlPromise("https://cdn.jsdelivr.net/npm/statsig-js/build/statsig-prod-web-sdk.min.js",
                function () {
                    return $window.statsig;
                }).then(function () {
                    $window.statsig.initialize(Gon.statsig_client_api_key, statsigUser, statsigOptions).then(() => {
                        defer.resolve();
                    }).catch(err => {
                        reportError(err, "statsig- error initializing user", statsigUser && statsigUser.userID, {statsigUser});
                        // do not reset the ref on success since we're not done and move forward to initialize statsig's instance
                        statsigInitializationPromiseReference = null;
                        defer.reject(err);
                    });
                }).catch(err => {
                    reportError(err, "statsig- error loading JS", statsigUser && statsigUser.userID, {statsigUser});
                    defer.reject(err);
                }).finally(() => {
                    statsigInitializationPromiseReference = null;
                });

            return defer.promise;
        };

        // checks whether the user attributes has changed
        const isStatsigUserIdentical = (statsigUser, newStatsigUser) => {
            const isCustomEqual = _.isEqual(newStatsigUser.custom, statsigUser.custom);
            const isCustomIDsEqual = _.isEqual(newStatsigUser.customIDs, statsigUser.customIDs);
            const isEmailEqual = _.isEqual(newStatsigUser.email, statsigUser.email);
            const isUserIDEqual = _.isEqual(newStatsigUser.userID, statsigUser.userID);
            const isEnvironmentEqual = statsigUser.statsigEnvironment.tier === envTier;

            return isCustomEqual && isCustomIDsEqual && isEmailEqual && isUserIDEqual && isEnvironmentEqual;
        };

        const updateStatsigUserForCurrentRequest = (newStatsigUser) => {
            const defer = $q.defer();
            const statsig = $window.statsig;

            // gets updated statsig user to determine weather another update is required
            const statsigUser = statsig && statsig.instance && statsig.instance.identity && statsig.instance.identity.user;
            const isIdentical = statsigUser && isStatsigUserIdentical(statsigUser,newStatsigUser);

            if(isIdentical){
                defer.resolve();
            } else {
                //statsig updateUser is called only once, and it's promise reference is saved to prevent value mismatch
                statsigUpdateUserPromiseReference = statsig.updateUser(newStatsigUser);

                statsigUpdateUserPromiseReference.then(() => {
                    defer.resolve();
                }).catch((err) => {
                    reportError(err, "statsig- error updating user for current request", statsigUser && statsigUser.userID, {statsigUser});
                    defer.reject(err);
                });
            }
            return defer.promise;
        };

        // responsible for updating the statsig user if the user attributes has changed
        const updateStatsigUserQueue = (newStatsigUser) => {
            const defer = $q.defer();

            // if statsig is still initializing - wait for it to finish and then update the user
            if (statsigInitializationPromiseReference) {
                statsigInitializationPromiseReference.then(() => {
                    updateStatsigUserForCurrentRequest(newStatsigUser).then(() => {
                        defer.resolve();
                    }).catch(err => {
                        reportError(err, "statsig- error updating user (after initialization)", newStatsigUser && newStatsigUser.userID, {newStatsigUser, hasPreviousUpdateRequest: true});
                        defer.reject(err);
                    });
                }).catch(err => {
                    reportError(err, "statsig- error updating user (error in initialization)", newStatsigUser && newStatsigUser.userID, {newStatsigUser, hasPreviousUpdateRequest: true});
                    defer.reject(err);
                });
            }
            // if already updating the statsig user for another request -
            // make sure it's over in order to check if another update is necessary
            else if(statsigUpdateUserPromiseReference){
                statsigUpdateUserPromiseReference.finally(() => {
                    updateStatsigUserForCurrentRequest(newStatsigUser).then(() => {
                        defer.resolve();
                    }).catch(err => {
                        reportError(err, "statsig- error updating user", newStatsigUser && newStatsigUser.userID, {newStatsigUser, hasPreviousUpdateRequest: true});
                        defer.reject(err);
                    });
                });
            } else {
                updateStatsigUserForCurrentRequest(newStatsigUser).then(() => {
                    defer.resolve();
                }).catch(err => {
                    reportError(err, "statsig- error updating user", newStatsigUser && newStatsigUser.userID, {newStatsigUser, hasPreviousUpdateRequest: false});
                    defer.reject(err);
                });
            }

            return defer.promise;
        };

        // this functionality is responsible for the initializing / updating the statsig user with the right attributes
        // it's called before each statsig gate / experiment check, to ensure the right values are returned
        const identify = (user) => {
            const defer = $q.defer();
            const statsig = $window.statsig;

            buildStatsigUser(user).then(newStatsigUser => {

                if (!newStatsigUser) {
                    reportError({}, "statsig- error building user", newStatsigUser && newStatsigUser.userID, {newStatsigUser});
                    defer.reject('statsig- error building user');
                }

                const userIdentificationFunction = ((statsig && statsig.instance) || statsigInitializationPromiseReference) ? updateStatsigUserQueue(newStatsigUser) : initializeStatsigUser(newStatsigUser, user);

                // waiting user identification to statsig (can be initialization or user update)
                userIdentificationFunction.then(() => {
                    defer.resolve();
                }).catch(err => {
                    defer.reject(err);
                });
            }).catch((err) => {
                reportError(err, "statsig- error building statsig user", user && user._id);
                defer.reject(err);
            });

            return defer.promise;
        };

        return {
            unidentify() {
                const statsig = $window.statsig;

                if (statsig && statsig.instance) {
                    statsig.shutdown();
                    statsigUpdateUserPromiseReference = null;
                    statsigInitializationPromiseReference = null;
                }
            },

            getExperimentConfig(experimentName, enableExposure = true) {
                const user = UsersManager.getCurrUser();
                const defer = $q.defer();

                let experiment = null;

                identify(user).then(() => {
                    const statsig = $window.statsig;

                    if (user && user.isExternalUser() || !enableExposure) {
                        experiment = statsig.getExperimentWithExposureLoggingDisabled(experimentName);
                    } else {
                        experiment = statsig.getExperiment(experimentName);
                    }
                }).catch(err => {
                    reportError(err, "statsig- error getting experiment", user && user._id, {experimentName});
                }).finally(() => defer.resolve(experiment));

                return defer.promise;
            },

            getExperimentConfigValue(experimentName, configName, fallbackValue, enableExposure = true) {
                let configValue = fallbackValue;
                const defer = $q.defer();

                this.getExperimentConfig(experimentName, enableExposure).then((experiment) => {
                    configValue = experiment.get(configName, fallbackValue);
                }).catch(err => {
                    const user = UsersManager.getCurrUser();
                    reportError(err, "statsig- error getting variant", user && user._id, {experimentName});
                }).finally(() => defer.resolve(configValue));

                return defer.promise;
            },

            isGateEnabled(gateName, fallbackValue = false) {
                const user = UsersManager.getCurrUser();
                const defer = $q.defer();

                let gateValue = fallbackValue;

                identify(user).then(() => {
                    const statsig = $window.statsig;

                    if (user && user.isExternalUser()) {
                        gateValue = statsig.checkGateWithExposureLoggingDisabled(gateName);
                    } else {
                        gateValue = statsig.checkGate(gateName);
                    }
                }).catch(err => {
                    reportError(err, "statsig- error checking gate", user && user._id, {gateName});
                }).finally(() => defer.resolve(gateValue));

                return defer.promise;
            }
        };
    };
}());
