
(function () {
    "use strict";


    // @ngInject
    function PvlServiceCtor(AnalyticsService, PopupMessageService, ModalService, AbTestService,
                                      WorkspaceFilesManager, WorkspacesManager, UsersManager, CompanyService, Enums, $translate, _, $q) {

        this.AnalyticsService = AnalyticsService;
        this.PopupMessageService = PopupMessageService;
        this.Enums = Enums;
        this.WorkspaceFilesManager = WorkspaceFilesManager;
        this.WorkspacesManager = WorkspacesManager;
        this.ModalService = ModalService;
        this.UsersManager = UsersManager;
        this.AbTestService = AbTestService;
        this.CompanyService = CompanyService;
        this.$translate = $translate;
        this.$q = $q;
        this._ = _;
    }

    Services.PvlService = Class(function () {
        return {
            constructor: PvlServiceCtor,

            getPvlConfigForEmailEditor: function getPvlConfigForEmailEditor(config, workspaceFile) {

                // valid file types for pvl
                var isValidFileTypeForPvl = workspaceFile.isProposal() || workspaceFile.isInvoice();

                // ownership
                var isOwner = workspaceFile.isOwnedByCurrUser();

                config.showSharePVL = isValidFileTypeForPvl && isOwner;
                config.pvl = {
                    workspace_id: workspaceFile.couple_card_id,
                    workspace_file_id: workspaceFile._id,
                    preferred_vendor_ids: []
                };

                return config;
            },

            editPreferredVendorsList: function editPreferredVendorsList(pvl) {

                var callback = function pvlDone(returnValue) {
                    if (returnValue) {
                        pvl.preferred_vendor_ids = returnValue.preferred_vendors_list.map(function(v) { return v.user._id; });
                        pvl.notify_vendors = returnValue.notify_vendors;
                    }
                    return pvl;
                }.bind(this);

                var promise = this.ModalService.openPVLCreationGuidanceModal(pvl);
                return promise.then(callback);
            },

            savePreferredVendorsList: function(pvl, flow_type) {

                // update pvl
                if(flow_type) { pvl.flow_type = flow_type; }

                return this.WorkspacesManager.savePreferredVendorsList(pvl).then(
                    function done(ret) {
                        this._.extendOwn(pvl, ret.data.preferred_vendors_lists[0]);
                        return ret.data;
                    }.bind(this)
                );
            },

            getLastRequestedPvlForWorkspace: function getLastRequestedPvlForWorkspace(workspace, currUserId) {
                var lastSentPVL, lastRequestedPVL;

                if(!workspace.preferred_vendors_lists) {
                    return {};
                }

                // get curr user ws files ids with pvl
                var userWorkspaceFiles = workspace.workspace_files.filter(function(wsFile) {
                    var isOwner = wsFile.owner._id === currUserId;
                    //var hasPvl = !!wsFile.preferred_vendors_list || !!wsFile.preferred_vendors_list_id;
                    return isOwner;
                });

                var userWorkspaceFilesIds = userWorkspaceFiles.map(function(wsFile) {
                    return wsFile._id;
                });

                // filter pvls from workspace that related to the user
                // we take the pvl object from the workspace and not from the ws file
                // because it's only updates on the workspace
                var pvls = workspace.preferred_vendors_lists.filter(function(pvl) {
                    return (!pvl.workspace_file_id || userWorkspaceFilesIds.indexOf(pvl.workspace_file_id) !== -1);
                });

                // go over and check if one of them is pending
                pvls.forEach(function(pvl) {

                    // update last sent
                    if (pvl.sent_on && (!lastSentPVL || lastSentPVL.sent_on < pvl.sent_on)) {
                        lastSentPVL = pvl;
                    }

                    // update last request
                    if (pvl.requested_at &&
                        (!lastRequestedPVL || lastRequestedPVL.requested_at < pvl.requested_at) &&
                        !this.isPvlRequestWasDismissedByUser(pvl, currUserId)) {
                        lastRequestedPVL = pvl;
                    }

                }.bind(this));

                return {
                    lastRequestedPVL: lastRequestedPVL,
                    lastSentPVL: lastSentPVL
                };
            },

            getPendingPvlRequestForWorkspace: function getPendingPvlRequestForWorkspace(workspace, currUserId) {
                var lastPvls = this.getLastRequestedPvlForWorkspace(workspace, currUserId);
                var lastRequestedPVL = lastPvls.lastRequestedPVL;
                var lastSentPVL = lastPvls.lastSentPVL;

                var hasPendingRequest = (lastRequestedPVL && (!lastSentPVL || lastSentPVL.sent_on < lastRequestedPVL.requested_at));
                return hasPendingRequest ? lastRequestedPVL : null;
            },

            // getLastRequestedPvlForWorkspace: function getLastRequestedPvlForWorkspace(workspace, currUserId) {
            //     var lastSentPVL, lastRequestedPVL;
            //
            //     // filter all workspace files for curr user, and that have pvl
            //     var userWorkspaceFiles = workspace.workspace_files.filter(function(wsFile) {
            //         var isOwner = wsFile.owner._id === currUserId;
            //         var hasPvl = !!wsFile.preferred_vendors_list || !!wsFile.preferred_vendors_list_id;
            //         return isOwner && hasPvl;
            //     }.bind(this));
            //
            //     // go over and check if one of them is pending
            //     userWorkspaceFiles.forEach(function(wsFile) {
            //         var pvl = wsFile.preferred_vendors_list;
            //
            //         // update last sent
            //         if (pvl.sent_on && (!lastSentPVL || lastSentPVL.sent_on < pvl.sent_on)) {
            //             lastSentPVL = pvl;
            //         }
            //
            //         // update last request
            //         if (pvl.requested_at &&
            //             (!lastRequestedPVL || lastRequestedPVL.requested_at < pvl.requested_at) &&
            //             !this.isPvlRequestWasDismissedByUser(pvl, currUserId)) {
            //             lastRequestedPVL = pvl;
            //         }
            //
            //     }.bind(this));
            //
            //     return {
            //         lastRequestedPVL: lastRequestedPVL,
            //         lastSentPVL: lastSentPVL
            //     };
            // },


            // getPendingPvlRequestForWorkspace: function getPendingPvlRequestForWorkspace(workspace, currUserId) {
            //     var lastSentPVL, lastRequestedPVL;
            //
            //     // filter all workspace files for curr user, and that have pvl
            //     var userWorkspaceFiles = workspace.workspace_files.filter(function(wsFile) {
            //         var isOwner = wsFile.owner._id === currUserId;
            //         var hasPvl = !!wsFile.preferred_vendors_list || !!wsFile.preferred_vendors_list_id;
            //         return isOwner && hasPvl;
            //     }.bind(this));
            //
            //     // go over and check if one of them is pending
            //     userWorkspaceFiles.forEach(function(wsFile) {
            //         var pvl = wsFile.preferred_vendors_list;
            //
            //         // update last sent
            //         if (pvl.sent_on && (!lastSentPVL || lastSentPVL.sent_on < pvl.sent_on)) {
            //             lastSentPVL = pvl;
            //         }
            //
            //         // update last request
            //         if (pvl.requested_at &&
            //             (!lastRequestedPVL || lastRequestedPVL.requested_at < pvl.requested_at) &&
            //             !this.isPvlRequestWasDismissedByUser(pvl, currUserId)) {
            //             lastRequestedPVL = pvl;
            //         }
            //
            //     }.bind(this));
            //
            //     var hasPendingRequest = (lastRequestedPVL && (!lastSentPVL || lastSentPVL.sent_on < lastRequestedPVL.requested_at));
            //     return hasPendingRequest ? lastRequestedPVL : null;
            // },

            isPvlRequestWasDismissedByUser: function isPvlRequestWasDismissedByUser(pvl, userId) {
                var dismissedByUserIds = pvl.request_dismissed_by_user_ids;
                return this._.contains(dismissedByUserIds, userId);
            },

            getRequestedVendorTypesStringFromPvl: function getRequestedVendorTypesStringFromPvl(pvl) {
                var ret = "";

                // validate
                if(!pvl || !pvl.requested_vendor_types) {
                    return ret;
                }

                // get company types translations
                var companyTypesTranslations = this.CompanyService.getCompanyTypesTranslationMap();

                // create string
                ret = pvl.requested_vendor_types.map(function(vendorType) {
                    return this.$translate.instant(companyTypesTranslations[vendorType]);
                }.bind(this)).join(", ");

                return ret;
            },

            getPvlRequestDismissOptions: function getPvlRequestDismissOptions() {
                var pvlRequestDismissOptionsTranslations = [
                    'WORKSPACE.FEED.REQUESTED_PVL.DISMISS_OPTIONS._ALREADY_DONE_',
                    'WORKSPACE.FEED.REQUESTED_PVL.DISMISS_OPTIONS._LATER_',
                    'WORKSPACE.FEED.REQUESTED_PVL.DISMISS_OPTIONS._NO_RECOMMENDATIONS_',
                    'WORKSPACE.FEED.REQUESTED_PVL.DISMISS_OPTIONS._NOT_RELEVANT_'
                ];

                pvlRequestDismissOptionsTranslations = this._.shuffle(pvlRequestDismissOptionsTranslations);
                pvlRequestDismissOptionsTranslations.push('WORKSPACE.FEED.REQUESTED_PVL.DISMISS_OPTIONS._OTHER_');

                var pvlRequestDismissOptions = pvlRequestDismissOptionsTranslations.map(function(opt) {
                    return this.$translate.instant(opt);
                }.bind(this));

                return pvlRequestDismissOptions;
            },

            sendPvlSentAnalytics: function sendPvlSentAnalytics(pvl, eventParams, context) {

                if(!pvl) {
                    return;
                }

                eventParams = eventParams || {};

                var pvlFlowType = pvl.flow_type;
                eventParams.pvl_flow_type = pvlFlowType;

                // send
                this.AnalyticsService.track(context, "send feed message with pvl", eventParams);

            },

            getPvlRequesterName: function getPvlRequesterName(workspace, pvl) {

                // if no pvl, return null
                if (!workspace || !pvl) {
                    return null;
                }

                // if no requester return null
                if(!pvl.requested_by_user_id) {
                    return null;
                }

                var name = null;

                workspace.members.forEach(function (user) {
                    if (user._id === pvl.requested_by_user_id) {
                        name = user.full_name;
                    }
                }.bind(this));

                return name;
            },

            showPreferredVendorList: function showPreferredVendorList(pvl_id, event_id) {
                return this.ModalService.openViewPreferredVendorsListModal(pvl_id, event_id);
            },

            getEmptyPvl: function getEmptyPvl() {
                return  {
                    preferred_vendor_ids: []
                };
            },

            hasAnyPvlOnWorkspace: function hasAnyPvlOnWorkspace(workspace) {
                return (workspace.preferred_vendors_lists && workspace.preferred_vendors_lists.length > 0);
            }
        };
    });
}());