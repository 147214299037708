(function () {
    "use strict";

    var COORD_NAME = {
        WIDTH: 'width',
        HEIGHT: 'height'
    };

    var IMAGE_EDGE_SIZE = 370;

    // @ngInject
    function UploadCanvaAssetsControllerCtor($log, $scope, $injector, $modalInstance, $timeout, $window, $, $translate, _,
                                            ImagesManager, PhotosUploadManager, AnalyticsService, CompaniesManager, Enums, PopupMessageService,
                                            showCropTab, showGalleryTab, showUploadTab, defaultTab, allowMultipleAttach,
                                            image, aspectRatio, showPreviewWhenCropping, startUploadFile, model, acceptFilesFilter,
                                            assetTypeForUpload, DeviceService, showCropTitle, footNoteText, branded, footNoteTextUploadTabDesktop, footNoteTextUploadTabMobile,
                                             onUploadFinish, UIUtils, DatadogRUMService, UsersManager, PendingTasksManager, ToastService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'UploadCanvaAssetsController';
        this.$ = $;
        this.modalInstance = $modalInstance;
        this.ImagesManager = ImagesManager;
        this.Enums = Enums;
        this.$log = $log;
        this.$timeout = $timeout;
        this.$window = $window;
        this.PhotosUploadManager = PhotosUploadManager;
        this.AnalyticsService = AnalyticsService;
        this.CompaniesManager = CompaniesManager;
        this.PopupMessageService = PopupMessageService;
        this.UIUtils = UIUtils;
        this.DatadogRUMService = DatadogRUMService;
        this.PendingTasksManager = PendingTasksManager;
        this.showCropTab = showCropTab;
        this.showGalleryTab = true;
        this.showUploadTab = showUploadTab;
        this.onUploadFinish = onUploadFinish;
        this.allowMultipleAttach = allowMultipleAttach;
        this.acceptFilesFilter = acceptFilesFilter || '';
        this.footNoteText = !_.isEmpty(footNoteText) && $translate.instant(footNoteText);
        this.footNoteTextUploadTabDesktop = !_.isEmpty(footNoteTextUploadTabDesktop) && $translate.instant(footNoteTextUploadTabDesktop);
        this.footNoteTextUploadTabMobile = !_.isEmpty(footNoteTextUploadTabMobile) && $translate.instant(footNoteTextUploadTabMobile);
        this.startUploadFile = startUploadFile;
        this.assetTypeForUpload = assetTypeForUpload || 'cover';
        this.showCropTitle = showCropTitle;
        this.company = this.CompaniesManager.getCurrCompany();
        this.isMobile = DeviceService.nxSmallBreakpoint();
        this.branded = branded;
        this.UsersManager = UsersManager;
        this.filesToAttach = [];
        this.ToastService = ToastService;
        this.$translate = $translate;

        this.currUser = this.UsersManager.getCurrUser();
        this.imagesContainerHeight = "75vh";


        this.galleryArgs = {
            margins: 22,
            rowHeight: 200
        };

        this.curFilter = 'PNG';

        this.onChange = (filter) =>  {
            this.curFilter = filter;
        };

        if (showCropTab && image) {
            this.image = image;
            if (this.image === 'no-image') {
                this.image = null;
            }
        }

        if (model) {
            this.model = model;
            if (this.image === 'no-model') {
                this.model = null;
            }
        }
        this.showLoader = true;
        this.galleryReady = false;

        var taskData = {
            user_id: this.currUser._id,
        };

        var self = this;
        var promise = self.PendingTasksManager.createPendingTask(self.PendingTasksManager.pendingTasksTypes.fetchCanvaDesigns, taskData);
        promise.then(function(res) {
            self.images = res.designs.map((design)=>{
                if (!design.thumbnail) {
                    return false;
                }
                return {
                    id: design.id,
                    title: design.title,
                    thumbnailUrl: design.thumbnail.url,
                    thumbnailHeight: design.thumbnail.height,
                    thumbnailWidth: design.thumbnail.width,
                    editUrl: design.urls.edit_url,
                    viewUrl: design.urls.view_url,
                    pageCount: design.page_count
                };
            });
            self.$timeout(function () {
                self.galleryReady = true;
                self.showLoader = false;
            }, 0);
        }).catch((err) => {
            var lastError = 'ERRORS.SERVER_API._UNEXPECTED_';
            self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, lastError);
            self.cancel();
        }).finally(() => self.fetchedCanvaDesigns = true);

        this.fileReaderSupported = this.$window.FileReader !== null;
        this.isFileDragged = false;
        this.filesDropped = false;
        this.uploadInitialState = true;
        this.isMobile = DeviceService.checkIfMobileSize();
        this.isClosingDisabled = false;

        this.tabs = [
            {title: "CANVA DESIGNS", panel: "crop-tabs-1"}
        ];

        this.showPreviewWhenCropping = showPreviewWhenCropping;
        this.aspectRatio = aspectRatio;

        this.selectedFiles = null;
        this.filesToUpload = null;
        this.limitUploadQuantity = 5;

        this.errorMessage = null;

        // 15 MB limit
        this.fileSizeLimit = 15 * 1024 * 1024;

        this.addCoverImage = function addCoverImage(images) {
            images.forEach(function(image) {
                this.company.cover_images.push(image);
            }.bind(this));
        }.bind(this),

            $scope.$watch('uploadCanvaAssetsVm.selectedFiles', function (newValue, oldValue) {
                var filesToUpload;
                this.errorMessage = null;
                if (newValue && !angular.isArray(newValue)) {
                    //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                    //and an array when ngf-multiple is set to true
                    //align it to be an array
                    filesToUpload = [newValue];
                } else {
                    filesToUpload = this.selectedFiles;
                }
                var removedLargeFiles = false;
                if (filesToUpload != null && filesToUpload.length > 0) {
                    var cleanedFiles = filesToUpload.filter(function (file) {
                        return file.size <= this.fileSizeLimit;
                    }.bind(this));

                    if (cleanedFiles.length < filesToUpload.length) {
                        removedLargeFiles = true;
                        filesToUpload = cleanedFiles;
                    }
                }

                this.filesToUpload = filesToUpload;

                if (removedLargeFiles) {
                    this.errorMessage = 'UPLOAD_FILE._ERROR_FILE_TOO_LARGE_';
                }
            }.bind(this));

        this._load();

        //if the selection of files was done outside of this dialog and we need to start uploading it right away
        if (startUploadFile) {
            this.onFilesDropped(startUploadFile);
            this.selectedFiles = startUploadFile;
        }

        this.assetClicked = this.assetClicked.bind(this);
    }

    Controllers.UploadCanvaAssetsController = Class(Controllers.BaseController, {
        constructor: UploadCanvaAssetsControllerCtor,

        _load: function _load() {

        },

        changeImageEdgeSizeOnMobile: function changeImageEdgeSizeOnMobile() {
            if (angular.element(this.$window).width() < 640) {
                IMAGE_EDGE_SIZE = 200;
            } else {
                IMAGE_EDGE_SIZE = 370;
            }
        },

        onGalleryComplete: function onGalleryComplete() {
            this.$timeout(function () {
                this.galleryReady = true;
            }.bind(this), 0);
        },

        cancel: function cancel() {
            // Dismiss selected assets
            this.images = this.company.cover_images;
            this.files = this.company.company_assets;

            this.modalInstance.dismiss('cancel');
        },

        startImport: function startImport() {
            var self = this;
            self.isClosingDisabled = true;

            var taskData = {
                files_to_attach: self.filesToAttach,
                format: self.curFilter.value
            };

            var promise = self.PendingTasksManager.createPendingTask(self.PendingTasksManager.pendingTasksTypes.exportCanvaDesigns, taskData);

            self.ToastService.showInfo({
                contentTranslation: self.$translate.instant('CANVA_INTEGRATION.TOAST_IMPORT_IN_PROGRESS', {count: self.filesToAttach.length}),
                iconCssClass: 'icon icon-hb-nx-check-circle-progress',
                dismissOnTimeout: true,
                dismissButton: true,
                timeout: 5000
            });

            // self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.info, 'Import in progress. Selected designs will be added to your library soon' );
            self.modalInstance.close();

            promise.then(function(res) {
                self.CompaniesManager.getCurrCompany(true);
                self.ToastService.showSuccess({
                    contentTranslation: 'CANVA_INTEGRATION.TOAST_IMPORT_COMPLETED',
                    iconCssClass: 'icon icon-hb-nx-check-mark-circle-16',
                    dismissOnTimeout: true,
                    dismissButton: true,
                    timeout: 5000
                });

                if(self.onUploadFinish){
                    self.onUploadFinish();
                }
            }).catch((err) => {
                if (err && typeof err === 'string') {
                    self.lastError = err;
                }
                else {
                    self.lastError = 'ERRORS.SERVER_API._UNEXPECTED_';
                }

                self.ToastService.showError({
                    content: "Canva import failed: " + self.lastError,
                    dismissOnTimeout: true,
                    timeout: 5000,
                    dismissButton: true
                });

                // self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, self.lastError);
            }).finally(() => self.requestInProgress = false);


            // this.UsersManager.saveCanvaDesigns(this.currUser, this.filesToAttach)
            //     .then(function success(resp) {
            //         this.modalInstance.close();
            //     }.bind(this))
            //     .catch(function fail(resp) {
            //         this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ACCOUNT.EMAIL_INTEGRATION.ERRORS._GETTING_AVAILABLE_EMAIL_PROVIDERS_');
            //     }.bind(this))
            //     .finally(function finallyHandler() {
            //         this.fetchedCanvaDesigns = true;
            //     }.bind(this));
        },

        selectGalleryImage: function selectGalleryImage(img) {

        },

        normalizeCoordinate: function normalizeCoordinate(size, type) {
            if (!this.image) {
                return;
            }

            switch (type) {
                case COORD_NAME.WIDTH:
                    return size / this.uiWidth * this.image.width;
                case COORD_NAME.HEIGHT:
                    return size / this.uiHeight * this.image.height;
                default:
                    return size;
            }
        },

        selectGalleryFile: function selectGalleryFile(file) {
            if (!file.size || file.size <= this.fileSizeLimit) {
                this.filesToAttach = this.filesToAttach.concat([file]);
            }
        },

        removeGalleryFile: function removeGalleryFile(file) {
            var index = this.filesToAttach.indexOf(file);

            if (index >= 0) {
                this.filesToAttach = this.filesToAttach.filter(function (f, i) {
                    return i !== index;
                });
            }
        },


        onFileSelected: function onFileSelected($files) {
            if ($files.length > 0) {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_selected, this.analyticsProperties());
                this.uploadFiles($files);
            }
        },

        onFilesDropped: function onFilesDropped($files) {
            this.isFileDragged = false;
            this.filesDropped = true;

            if (!$files.length) {
                return;
            }

            this.uploadInitialState = false;
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.files_dragged_and_dropped, this.analyticsProperties());

            if (this.showCropTab) {
                this.uploadFiles($files);
            } else {
                this.generateThumbs($files);
            }
        },

        uploadFiles: function uploadFiles($files) {
            var self = this;
            var uploadingFileViewModel;
            if ($files && $files.length > 1) {
                self.multipleFilesDraggedWarning = true;
            }

            //remove large files before upload
            var filesToUpload = $files;
            if (filesToUpload != null && filesToUpload.length > 0) {
                var fileSizeLimit = this.fileSizeLimit;
                var cleanedFiles = filesToUpload.filter(function (file) {
                    return file.size <= fileSizeLimit;
                });
                if (cleanedFiles.length < filesToUpload.length) {
                    filesToUpload = cleanedFiles;
                }
            }
            $files = filesToUpload;

            if ($files && $files.length && $files.length > 0) {

                this.limitUploadQuantity = 1;

                //we take the first one since we allow only one file to be uploaded here
                uploadingFileViewModel = this.uploadingFileViewModel = {
                    instance: this.company, //the instance of the model containing these files
                    type: this.assetTypeForUpload,
                    dontUpdateServer: true,
                    uploading: false,
                    uploaded: true,
                    dataUrl: null,
                    url: null,
                    file: $files[0],
                    name: $files[0].name,
                    progress: 0
                };
            } else {
                return;
            }
            self.uploading = true;

            this.PhotosUploadManager.getUpdatedModelData(this.uploadingFileViewModel);

            var promise = this.PhotosUploadManager.uploadPhoto($files, this.uploadingFileViewModel, this);

            this.generateThumb(this.uploadingFileViewModel);

            promise
                .then(function success() {


                    self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_successfully_uploaded, {
                        companyId: self.company._id,
                        key: uploadingFileViewModel.key
                    });

                    uploadingFileViewModel.url = uploadingFileViewModel.file.s3Response.url;

                    return self.CompaniesManager.createCompanyImage(self.company, uploadingFileViewModel.file.s3Response, uploadingFileViewModel.type).then(function (result) {
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_saved_on_company_model, {
                            companyId: self.company._id,
                            name: uploadingFileViewModel.name
                        });

                        //get the last image from the result and assume that this is the one that we added
                        self.$timeout(function () {
                            //do it in a timeout so the 100% will show.

                            var images = [];
                            var addedImage = null;

                            if (self.model) {
                                switch (self.model.type) {
                                    case "icon":
                                        images.push(result.data.icon_image);
                                        break;
                                    default:
                                        images = result.data.cover_images;
                                }
                            } else {
                                images = result.data.cover_images;
                            }

                            angular.forEach(images, function (image, key) {
                                if (image.phash === uploadingFileViewModel.file.s3Response.phash) {
                                    addedImage = image;
                                }
                            });


                            //var addedImage = images[images.length - 1];
                            self.selectGalleryImage(addedImage);
                        }, 100);
                    });
                })
                .catch(function error(resp) {
                    if (resp === null) {
                        //this is a result of a delete of a file during an upload. remove it from the view model since we know the upload stopped
                        //before it was inserted to our company model
                        self.AnalyticsService.track(self, self.AnalyticsService.analytics_events.cover_image_deleted_while_uploading, {
                            companyId: self.company._id,
                            key: uploadingFileViewModel.key
                        });

                        self.uploading = false;
                        uploadingFileViewModel.uploadFailedInTheMiddle = true;
                        return;
                    }

                    if (resp) {
                        self.PopupMessageService.showAlert(self.PopupMessageService.severityTypes.error, 'COMPANY_SETTING.GALLERY._ERROR_UPLOADING_FILE_');
                        var message = 'Error saving the file: ' + uploadingFileViewModel.name + ' resp: ' + resp;
                        self.AnalyticsService.trackError(self, self.AnalyticsService.analytics_events.error_unable_to_add_image_to_company_settings, message, {
                            companyId: self.company._id
                        });
                        uploadingFileViewModel.uploadFailedInTheMiddle = true;
                    }
                })
                .finally(function () {
                    if (!uploadingFileViewModel.uploadFailedInTheMiddle) {
                        uploadingFileViewModel.progress = 100;
                    }
                    //clean the uploaded file model so the user will be able to upload another file if he goes back to this tab.
                    self.$timeout(function () {
                        self.uploadingFileViewModel = null;
                    }, 500);

                    self.uploading = false;
                    self.uploadInitialState = true;
                });

            this.uploadingFileViewModel.file.uploadAPI.progress(function (percentUploaded) {
                //save the last 5 percent for the upload to the company model.
                self.uploadingFileViewModel.progress = parseInt(0.95 * percentUploaded);
            });
        },

        generateThumbs: function generateThumb($files) {
            var self = this;
            var files;

            if ($files && !angular.isArray($files)) {
                //the api of the angular-file-upload is sending one file when ngf-multiple is set to false
                //and an array when ngf-multiple is set to true
                //align it to be an array
                files = [$files];
            } else {
                files = $files;
            }

            if (files != null && files.length > 0) {
                for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
                    var file = files[fileIndex];
                    if (this.fileReaderSupported) {
                        //Execution
                        if (!file.dataUrl) {
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = function (e) {
                                var file = this;
                                self.$timeout(function () {
                                    file.dataUrl = e.target.result;
                                });
                            }.bind(file);
                        }
                    }
                    self.selectGalleryFile(file);
                }
            }
        },

        generateThumb: function generateThumb(fileViewModel) {
            var fileReaderSupported = this.$window.FileReader != null;

            if (fileReaderSupported && fileViewModel.file.type.indexOf('image') > -1) {
                this.readFileAsDataUrl(fileViewModel);
            }
        },

        readFileAsDataUrl: function readFileAsDataUrl(fileViewModel) {
            var self = this;

            var fileReader = new FileReader();
            fileReader.readAsDataURL(fileViewModel.file);
            fileReader.onload = function (e) {
                self.$timeout(function () {
                    fileViewModel.dataUrl = e.target.result;
                });
            };
        },

        analyticsProperties: function analyticsProperties() {
            return { companyId: this.company._id };
        },

        assetClicked: function assetClicked(asset) {
            // if crop tab enabled, multiple selection is not relevant
            if (this.showCropTab) {
                return;
            }

            this.isAssetSelected(asset) ?
                this.removeGalleryFile(asset) :
                this.selectGalleryFile(asset);
        },

        isImageTypeOfFile: function isImageTypeOfFile(file) {
            return (file.type.indexOf('image') > -1);
        },

        isAssetSelected: function isAssetSelected(asset) {
            for (var i in this.filesToAttach) {
                if (this.filesToAttach[i].id === asset.id) {
                    return true;
                }
            }
            return false;
        },

        imagePositionInSelectedAssets: function (asset) {
            var counter = 0;
            for (var i in this.filesToAttach) {
                var fileToAttach = this.filesToAttach[i];
                counter++;
                if (fileToAttach.id === asset.id) {
                    return counter;
                }
            }
        },

        assetPositionInSelectedAssets: function (asset) {
            var counter = 0;
            for (var i in this.filesToAttach) {
                var fileToAttach = this.filesToAttach[i];
                if (fileToAttach._type !== "CoverImage") {
                    counter++;
                }

                if (fileToAttach._id === asset._id) {
                    return counter;
                }
            }
            return -1;
        },

        drag: function drag($isDragging) {
            this.isFileDragged = $isDragging;
            this.$scope.$applyAsync();
        },

        openCanvaWebsite: function openCanvaWebsite() {
            this.$window.open("https://www.canva.com/", '_blank');
        }
    });
}());
