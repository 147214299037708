(function () {
  "use strict";

  // @ngInject
  function ImageServiceConstructor($, $q, $timeout, ModalService, ImagesManager, AppConfigService, AbTestService,
                                   GoogleTagManagerService, DatadogRUMService) {
    // Dependency on jQuery for access to jQuery Cloundinary API.
    // Consider using the vanilla/node.js Cloudinary API instead.
    // Requires adding an additional dependency and refactoring out
    // all $.cloudinary references
    this.$ = $;
    this.$q = $q;
    this.$timeout = $timeout;
    this.ModalService = ModalService;
    this.ImagesManager = ImagesManager;
    this.AppConfigService = AppConfigService;
    this.AbTestService = AbTestService;
    this.GoogleTagManagerService = GoogleTagManagerService;
    this.DatadogRUMService = DatadogRUMService;
  }

  Services.ImageService = Class(function() {

    return {
      constructor: ImageServiceConstructor,

      /**
       * We have to strip everything before "/upload" because the cloudinary
       * API expects just the public key.
       *
       * TODO: Remove once all assets have a cloudinary id.
       *
       * @param  {string} url - A full path to a cloudinary asset
       * @return {string}
       */
      _extractCloudinaryPublicKey: function _extractCloudinaryPublicKey(url) {
        var pattern = /(honeybook|hb-users|hb-dev)\/image\/upload\/(.*)/;
        return url.match(pattern)[2];
      },

      /**
       * Generates a cloudinary url with image manipulation capabilities
       * given a full path to an asset on the cloudinary CDN.
       *
       * TODO: Remove once all assets have a cloudinary id.
       *
       * @param  {string} url - A path to a cloundinary asset
       * @param  {object} transformation - Image transformations,
       *                  see: http://cloudinary.com/documentation/image_transformations
       * @return {string}
       */
      getLegacyCloudinaryUrl: function getLegacyCloundinaryUrl(url, transformation) {
        var key = this._extractCloudinaryPublicKey(url);
        return this.$.cloudinary.url(key, transformation);
      },

        /**
         * Generates a cloudinary url with image manipulation capabilities
         * given a cloudinary image key.
         *
         * TODO: Remove once all assets have a cloudinary id.
         *
         * @param  {string} url - A path to a cloundinary asset
         * @param  {object} transformation - Image transformations,
         *                  see: http://cloudinary.com/documentation/image_transformations
         * @return {string}
         */
        getCloudinaryUrl: function getLegacyCloundinaryUrl(key, transformation) {
            return this.$.cloudinary.url(key, transformation);
        },

        getHBImageCloudinaryUrl: function getHBImageCloudinaryUrl(image, forceDimensions) {

            var width = (forceDimensions && forceDimensions.width) ? forceDimensions.width : image.position_w;
            var height = (forceDimensions && forceDimensions.height) ? forceDimensions.height : image.position_h;

            var transformation = {transformation: [{
                crop: 'crop',
                width: width,
                height: height,
                x: image.position_x,
                y: image.position_y,
                quality: 'auto:best',
                flags: 'lossy',
                fetch_format: 'auto',
                dpr: "auto"
            }]};

            return this.getCloudinaryUrl(image.cloudinary_public_id, transformation);
        },

        uploadCompanyIcon: function uploadCompanyIcon(company) {
            var model = {
                instance: company,
                type: 'icon'
            };

            var defaultTab = company.icon_image && company.icon_image.cloudinary_public_id && !company.icon_image.is_default_company_icon ? 'Crop' : 'Upload';

            var options = {
                selectedImage: company.icon_image,
                imageDisplayRatio: 1,
                showPreviewWhenCroppingDisplay: true,
                skipSavingToServer: false,
                title: 'MEDIA_MODAL.TITLE._UPLOAD_NEW_LOGO_',
                defaultTab: defaultTab,
                model: model,
                showGalleryTab: false,
                footNoteText: 'MEDIA_MODAL._FOOT_NOTE_',
                footNoteTextUploadTabDesktop: 'MEDIA_MODAL._UPLOAD_FOOT_NOTE_DESKTOP_',
                footNoteTextUploadTabMobile: 'MEDIA_MODAL._UPLOAD_FOOT_NOTE_MOBILE_'

            };

            return this.chooseImage(options)
                .then(function successUploadImage() {
                        this.GoogleTagManagerService.onCompanyIconUpload();
                }.bind(this))
                .catch(function (err) {
                    if (err !== 'cancel') {
                        this.DatadogRUMService.addError(err, {
                            message: '[ImageService] Error uploading company icon'
                        });
                    }
                    throw err;
                }.bind(this));
        },

        chooseImage: function chooseImage(options) {
            var selectedImage = options.selectedImage;
            var imageDisplayRatio = options.imageDisplayRatio;
            var showPreviewWhenCroppingDisplay = options.showPreviewWhenCroppingDisplay;
            var skipSavingToServer = options.skipSavingToServer;
            var title = options.title;
            var defaultTab = options.defaultTab || "Crop";
            var model = options.model;
            var showCropTab = angular.isUndefined(options.showCropTab) ? true : options.showCropTab;
            var showGalleryTab = angular.isUndefined(options.showGalleryTab) ? true : options.showGalleryTab;
            var showUploadTab = angular.isUndefined(options.showUploadTab) ? true : options.showUploadTab;
            var footNoteText = options.footNoteText;
            var footNoteTextUploadTabMobile = options.footNoteTextUploadTabMobile;
            var footNoteTextUploadTabDesktop = options.footNoteTextUploadTabDesktop;
            var deferred = this.$q.defer();

            // make that modal go.
            this.ModalService.openAttachAssetModal(showCropTab, showGalleryTab, showUploadTab, defaultTab,
                false, false, selectedImage, imageDisplayRatio, showPreviewWhenCroppingDisplay,
                false, "image/*", model,'', title, footNoteText, false, footNoteTextUploadTabDesktop, footNoteTextUploadTabMobile).then(

                    function success(newImage) {
                        if (skipSavingToServer){
                            deferred.resolve(newImage);
                        } else {
                            this.ImagesManager.saveImageReposition(newImage)
                                .then(function success(res) {
                                    res.data.forEach(function readResult(image) {
                                        switch (image._type) {
                                            case 'CoverImage':
                                                this.ImagesManager.updateCoverImageInCompany(image);
                                                break;
                                            case 'CompanyIconImage':
                                                this.ImagesManager.updateIconImageInCompany(image);
                                                break;
                                            default:
                                                if (image._id === newImage._id) {
                                                    //extend the image we have with the image that was returned from the last API
                                                    angular.extend(newImage, image);
                                                }
                                                break;
                                        }
                                    }.bind(this));
                                    deferred.resolve(newImage);
                                }.bind(this))
                                .catch(function (err) {
                                    this.$log.error('Error while trying to save image reposition', err);
                                    this.DatadogRUMService.addError(err, {
                                        message: '[ImageService] chooseImage() Error while trying to save image reposition'
                                    });
                                    deferred.reject(err);
                                }.bind(this));
                        }

                    }.bind(this)
                )
                .catch(function(err) {
                    this.DatadogRUMService.addError(err, {
                        message: '[ImageService] chooseImage() Error while openAttachAssetModal'
                    });
                    deferred.reject(err);
                }.bind(this));

            return deferred.promise;
        }
    };
  });

}());
