(function () {
    'use strict';

    // @ngInject
    function CanvaRedirectControllerCtor($scope, $injector, $location, $log, $q, InitialAppLoadParamsService, AnalyticsService, AppStates, UsersManager, PopupMessageService, Enums, $window) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CanvaRedirectController';
        AnalyticsService.trackPageView(this, 'CanvaRedirectView');
        this.$log = $log;
        this.$q = $q;

        this.AppStates = AppStates;
        this.UsersManager = UsersManager;
        this.user = this.UsersManager.getCurrUser();
        this.PopupMessageService = PopupMessageService;
        this.AnalyticsService = AnalyticsService;
        this.$window = $window;

        this.Enums = Enums;

        var queryStringParams = InitialAppLoadParamsService.getAllLoadParams();
        this.$log.info("queryStringParams debug: " + JSON.stringify(queryStringParams));
        InitialAppLoadParamsService.cleanParamsFromView(['code']);
        var originState = 'library';

        if (queryStringParams.code) {
            this.UsersManager.setCanvaAuthCode(queryStringParams.code)
                .then(function success() {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.save_canva_integration_auth_code);
                    this.UsersManager.forceFetchCurrUser();
                    this.redirectUserBackToOriginPage(originState);
                }.bind(this))
                .catch(function fail(resp) {
                    this.AnalyticsService.trackError(this, this.AnalyticsService.analytics_events.save_canva_integration_auth_code, resp);

                    if (resp && resp.data && resp.data.error_type && resp.data.error_message && resp.data.error_type === "HBUserError") {

                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, resp.data.error_message, function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    else {
                        this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_', function success() {
                            this.redirectUserBackToOriginPage(originState);
                        }.bind(this));
                    }
                    this.$log.error('failed to save the auth-code on the canva integration model');
                }.bind(this));

        } else {
            this.$log.error('Error in canva authentication process: ' + $location.url());
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.canva_integration_error);
            this.redirectUserBackToOriginPage(originState);
        }
    }

    Controllers.CanvaRedirectController = Class(Controllers.BaseController, {
        constructor: CanvaRedirectControllerCtor,

        redirectUserBackToOriginPage: function redirectUserBackToOriginPage(originState) {
            const canva_integration_redirect_to_flow = JSON.parse(localStorage.getItem("canva_integration_redirect_to_flow"));
            if(canva_integration_redirect_to_flow && canva_integration_redirect_to_flow.flowId){
                this.$window.location.href = this.$window.location.origin + '/app/flow/' + canva_integration_redirect_to_flow.flowId + '/edit?openCanvaImport=true';
                localStorage.removeItem('canva_integration_redirect_to_flow');
                return;
            }

            switch (originState) {
                case 'integrations':
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    break;
                case 'library':
                    this.goToState(this.AppStates.root_core_navigation_settings_companySettings_library, {openCanvaImport: true});
                    break;
                default:
                    this.PopupMessageService.showAlert(this.PopupMessageService.severityTypes.error, 'ERRORS.SERVER_API._TRY_AGAIN_');
                    this.$log.error('could not identify the origin_state code. this should not happen');
                    this.goToState(this.AppStates.root_core_navigation_settings_company_integrations);
                    break;
            }
        }
    });
}());