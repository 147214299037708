
Directives.EventsListDirective = () => {

    // @ngInject

    class EventsListDirectiveController extends Controllers.BaseControllerES6 {

        constructor($scope, $injector, $state, $timeout, AppStates, UsersManager, EventsManager, EventService, PretrialersService,
                    DeviceService, $window, Enums, WorkspacesManager, WorkspaceService, AnalyticsService, SearchablesManager, ClientPortalService) {
            super($scope, $injector);
            this.__objectType = 'EventsListDirectiveController';

            this.scrollFlag = false;
            this.AppStates = AppStates;
            this.$state = $state;
            this.$scope = $scope;
            this.$window = $window;
            this.$timeout = $timeout;
            this.UsersManager = UsersManager;
            this.EventsManager = EventsManager;
            this.EventService = EventService;
            this.AnalyticsService = AnalyticsService;
            this.SearchablesManager = SearchablesManager;
            this.DeviceService = DeviceService;
            this.Enums = Enums;
            this.WorkspaceService = WorkspaceService;
            this.WorkspacesManager = WorkspacesManager;
            this.PretrialersService = PretrialersService;
            this.ClientPortalService = ClientPortalService;

            this.sortByOptions = [
                {
                    title: 'PROJECTS_DRAWER.SORT.ACTIVITY._TITLE_',
                    menuLabel: 'PROJECTS_DRAWER.SORT.ACTIVITY._MENU_TITLE_',
                    sortField: 'last_activity_date',
                    sortable: true
                },
                {
                    title: 'PROJECTS_DRAWER.SORT.DATE._TITLE_',
                    menuLabel: 'PROJECTS_DRAWER.SORT.DATE._MENU_TITLE_',
                    sortField: 'event_date',
                    sortable: true
                },
                {
                    title: 'PROJECTS_DRAWER.SORT.NAME._TITLE_',
                    menuLabel: 'PROJECTS_DRAWER.SORT.NAME._MENU_TITLE_',
                    sortField: 'event_name',
                    sortable: true
                },
                {
                    title: 'PROJECTS_DRAWER.SORT.SEARCH._TITLE_',
                    sortable: false
                }
            ];

            this.pipelineStages = [];
            this.pipelineStageById = {};
            this.allStage = this._buildStage(null, '_ALL_ACTIVE_', null, 0, false, false);
            this.archivedStage = this._buildStage(null, '_ARCHIVED_', null, 0, true, false);
            this.untrackedStage = this._buildStage(null, '_UNTRACKED_PROJECTS_', null, 0, false, true);

            this.user = this.UsersManager.getCurrUser();
            this.isMobile = DeviceService.checkIfMobileSize();
            this.selectedSort = this.sortByOptions[0];
            this.showPipeline = true;
            this.isSearchTriggered = false;
            this.currentEventsPageAsc = 0;
            this.currentEventsPageDesc = 0;
            this.eventSearchResults = [];
            this.eventsToShow = [];
            this.sortDirection = -1;
            this.hasMoreEventsToShow = false;
            this.eventSearchResults = [];
            this.selectedStage = this.allStage;
            this.isSearchFocused = false;

            this._loadProjects();
            if (this.user.isVendor()) {
                this._loadPipelineCount();
                this.register(EventsManager, 'projectCreated', this._reload);
                this.register(WorkspacesManager, 'workspacesStageUpdated', this._reload);
            }
        }

        isRouteActive(root) {
            return this.$state.current.name && this.$state.current.name.indexOf(root) !== -1;
        }

        scrollToSelectedEvent() {
            this.scrollFlag = true;
        }

        onStageClick(stage) {
            this._resetSearch();
            this.selectedStage = stage;
            this.getEvents(1);
        }

        onProjectClicked(eventId, workspaceId){
            let tfile = this.eventsToShow.filter((ev) => { return ev._id === eventId; }),
                fname = tfile? (tfile.length? tfile[0].event_name : undefined) : undefined;

            // collapse on mobile
            if (this.DeviceService.checkIfMobileSize() || this.DeviceService.checkIfTouchDevice()) {
                this._close();
            }

            // navigate to the right place according to the persistency
            let stateToGo = null;

            if (workspaceId) {
                stateToGo = this.EventService.getEventDefaultState({
                    _id: eventId,
                    workspaces: [{
                        _id: workspaceId
                    }]
                }, true);
            }
            else {
                stateToGo = this.EventService.getEventDefaultState(eventId);
            }

            this.$window.currentSubframe = fname; // oh, the use of window :/ (done by john. I (eyal) mereged it. we need to change this)

            let params = angular.extend({disableScrollToTop: true}, stateToGo.params);
            this.$state.go(stateToGo.name, params);
        }

        onCloseClick() {
            if (this.isSearchFocused) {
                this._resetSearch();
                this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.reset_projects_search);
            }
            else {
                this._close();
            }
        }

        onSearchFocus() {
            this.isSearchFocused = true;
        }

        onSearchBlur() {
            this.isSearchFocused = false;
        }

        eventSearchInputChanged() {

            this.isSearchTriggered = true;

            if (!this.eventSearchTxt) {
                this._resetSearch();
            }
            else if (this.eventSearchTxt.length >= 3) {
                this.loadingProjects = true;
                this.selectedSort = this.sortByOptions[3];
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.search_in_left_panel_events, {searchd_term: this.eventSearchTxt});

                if (this.user.isClient()) {
                    this.eventSearchResults = this.eventsToShow.filter((event)=> event.eventName.includes(this.eventSearchTxt));
                    return
                }

                this.eventSearchResults = this.SearchablesManager.search(this.eventSearchTxt, 'couple_cards', {hideTeamMembers: true});
                this.register(this.eventSearchResults, 'success', () => {
                    this.loadingProjects = false;
                });

                this.register(this.eventSearchResults, 'error', () => {
                    this._resetSearch();
                });
            }
        }

        onSortClick(sortOption) {
            this.setEventsList(sortOption);
        }

        setEventsList(sortOption) {
            this.currentEventsPageAsc = 0;
            this.currentEventsPageDesc = 0;
            this.selectedSort = sortOption;
            this._loadProjects();
        }

        clickDropdown() {
            this.dropboxActivated = !this.dropboxActivated;
        }

        hoverOutDropdown() {
            this.dropboxActivated = false;
        }

        scrolledToBottom() {
            if (this.hasMoreEventsToShow && !this.loadingProjects) {
                this.getNextEventsPage();
            }
        }

        getNextEventsPage() {

            if (this.sortDirection === -1) {
                this.currentEventsPageDesc += 1;
            }
            else {
                this.currentEventsPageAsc += 1;
            }

            this._getEventsPage();
        }

        getEvents(pageToFetch) {

            this.hasMoreEventsToShow = false; //assume its the end until res arrives
            this.loadingProjects = true;

            let getEventsParams = {
                page: pageToFetch,
                perPage: 30,
                sortColumn: this.selectedSort && this.selectedSort.sortField,
                sortDirection: this.sortDirection,
                stageId: this.selectedStage && this.selectedStage._id,
                showArchivedWorkspaces: !!(this.selectedStage && this.selectedStage.showArchived),
                showUntrackedWorkspaces: !!(this.selectedStage && this.selectedStage.showUntracked)
            };

            this.EventsManager.getFilteredEvents(getEventsParams).then(function(resp) {

                if (pageToFetch === 1) {
                    this.eventsToShow.splice(0);
                    this.eventsToShow = resp.data.data;
                }
                else {
                    this.eventsToShow = this.eventsToShow.concat(resp.data.data);
                }

                this.hasMoreEventsToShow = !resp.isFullyFetched;
            }.bind(this))
            .finally(function final() {
                this.loadingProjects = false;
            }.bind(this));
        }

        setSortDirection() {
            this.sortDirection = (-1) * this.sortDirection;
        }

        getScrollId() {
            return `${this.EventService.loadedEventId}-${this.WorkspaceService.loadedWorkspaceId}`;
        }

        _loadPipelineCount() {
            this.WorkspacesManager.getPipelineWorkspacesCounts([this.user._id], false).then((resp) => {

                if (resp.data) {
                    let init = !this.pipelineStages.length;
                    angular.forEach(resp.data.user_pipeline_stages, (stage) => {
                        if (init) {
                            this.pipelineStageById[stage._id] = this._buildStage(stage._id, stage.name, stage.category, stage.counts.active, false);
                            this.pipelineStages.push(this.pipelineStageById[stage._id]);
                        }
                        else if (this.pipelineStageById[stage._id]) {
                            this.pipelineStageById[stage._id].count = stage.counts.active;
                        }
                    });

                    if (init) {
                        this.pipelineStages.unshift(this.allStage);
                        this.pipelineStages.push(this.archivedStage);
                        this.pipelineStages.push(this.untrackedStage);
                    }

                    this.allStage.count = resp.data.counts.active;
                    this.archivedStage.count = resp.data.counts.inactive;
                    this.untrackedStage.count = resp.data.counts.untracked;
                }
                else {
                    this.showPipeline = false;
                }


            });
        }

        _getEventsPage() {
            if (this.sortDirection === -1) {
                this.getEvents(this.currentEventsPageDesc);
            }
            else {
                this.getEvents(this.currentEventsPageAsc);
            }
        }

        _close() {
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.close_projects_drawer);
            this.appState.leftPanelEventOpen = false;
        }

        _resetSearch() {
            this.eventSearchTxt = null;
            this.eventSearchResults = [];
            var wasSearchTriggered = this.isSearchTriggered;
            this.isSearchTriggered = false;
            if (wasSearchTriggered) {
                this.setEventsList(this.sortByOptions[0]);
            }
        }

        _buildStage(id, name, category, count, showArchived, showUntracked) {
            return {
                _id: id,
                name: name,
                category: category,
                showArchived: showArchived,
                showUntracked: showUntracked,
                count: count
            };
        }

        _loadProjects() {
            if (this.user.isClient()) {
                this.EventService.getProjectsForClient().then((resp) => {
                    this.eventsToShow = resp.data.data;
                });
            }
            else {
                this.getNextEventsPage();
            }
        }

        _reload() {
            this.$timeout(() => {
                this._loadPipelineCount();
                this._getEventsPage();
            }, 2000);
        }
    }

    return {
        templateUrl: 'angular/app/modules/core/features/event/events_list/events_list_directive_template.html',
        controller: EventsListDirectiveController,
        controllerAs: 'eventsListVm',
        bindToController: true
    };
};
