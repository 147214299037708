(function () {
    "use strict";

    Controllers.TheknotRedirectController = class TheKnotRedirectController extends Controllers.BaseControllerES6 {
        // @ngInject
        constructor($scope, $injector) {
            super($scope, $injector);
            this.__objectType = 'TheKnotRedirectController';
        }
    };

}());

