(function () {
    "use strict";

    const PROJECT_DATE_FIELDS = ['event_date', 'event_end_date', 'event_time_start', 'event_time_end', 'event_timezone'];

    Controllers.RescheduleProjectContainerController = class RescheduleProjectContainerController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, $, AnalyticsService, $modalInstance, projectId, isUnpause, source, CompaniesManager, UIUtils,
                    TimezoneService, workspaceId, EventsManager, WorkspacesManager, $q, $translate, _, WorkflowManager,
                    RescheduleProjectService, WorkspaceFileService, UsersManager, $filter, $timeout, ModalService, PendingTasksManager, EmailPreviewManager) {
            super($scope, $injector);
            this.__objectType = 'RescheduleProjectContainerController';

            this.$ = $;
            this.AnalyticsService = AnalyticsService;
            this.ModalService = ModalService;
            this.PendingTasksManager = PendingTasksManager;
            this.EmailPreviewManager = EmailPreviewManager;
            this.$modalInstance = $modalInstance;
            this.EventsManager = EventsManager;
            this.WorkspacesManager = WorkspacesManager;
            this.WorkflowManager = WorkflowManager;
            this.CompaniesManager = CompaniesManager;
            this.TimezoneService = TimezoneService;
            this.RescheduleProjectService = RescheduleProjectService;
            this.source = source;
            this.WorkspaceFileService = WorkspaceFileService;
            this.$filter = $filter;
            this.$timeout = $timeout;
            this.UIUtils = UIUtils;
            this.user = UsersManager.getCurrUser();

            this.isUnpause = isUnpause;
            this.$q = $q;
            this.$translate = $translate;
            this._ = _;
            this.projectId = projectId;
            this.workspaceId = workspaceId;

            this.detachWorkflow = false;
            this.timezones = this.TimezoneService.getTimezones();
            this.shouldShowDateConflicts = this.CompaniesManager.getCurrCompany().isShowDateConflictsAutomationOn();
            this.AnalyticsService.trackLoad(this, this.AnalyticsService.analytics_events.reschedule_project_modal_open, {source: this.source});

            this.$scope.$on('onNewSignatoryOpen', () => this.hide());
            this.$scope.$on('onNewSignatoryClose', () => this.show());

            this.load();

            this.$timeout(() => {
                this.afterModalAnimation = true;
            }, 300); //this is the time that the animation of the modal takes
        }

        load() {
            this.loading = true;

            this.RescheduleProjectService.reset();
            const projectPromise = this.EventsManager.getEvent(this.projectId, true, true);
            const workspacePromise = this.WorkspacesManager.getWorkspace(this.workspaceId, true, true, true);
            const workflowPromise = this.WorkflowManager.getWorkflowInstance(this.workspaceId);

            this.initializeFirstStep();

            this.$q.all([projectPromise, workspacePromise, workflowPromise]).then(([project, workspace, workflow]) => {
                this.projectData = project.dataOnly();
                this.projectModel = project;
                this.RescheduleProjectService.setProject(this.projectData);
                this.workspace = workspace;
                this.workflow = workflow;

                this.filterFiles();
                this.setInitialTimezone();
                this.initializeConditionalSteps();
                this.setNextStepTitle();

                this.dateStep.ctaTitle = this.nextStepTitle;

                this.loading = false;
            });
        }

        initializeFirstStep() {
            this.steps = [];
            this.dateStep = this._buildStep(this.isUnpause ? 'RESCHEDULE_PROJECT.CHOOSE_DATE._UNPAUSE_TITLE_' : 'RESCHEDULE_PROJECT.CHOOSE_DATE._TITLE_', undefined, 'reschedule-project--choose-date');
            this.steps.push(this.dateStep);
            this.currentStepIndex = 0;
            this.currentStep = this.dateStep;
        }

        initializeConditionalSteps() {
            this.workflowStep = this._buildStep('RESCHEDULE_PROJECT.WORKFLOW._TITLE_', 'RESCHEDULE_PROJECT.NEXT_CTA._WORKFLOW_', 'reschedule-project--workflow');
            this.paymentsStep = this._buildStep('RESCHEDULE_PROJECT.UPDATE_PAYMENT._TITLE_', 'RESCHEDULE_PROJECT.NEXT_CTA._PAYMENTS_', 'reschedule-project--payments');
            this.chooseContractStep = this._buildStep('RESCHEDULE_PROJECT.CHOOSE_CONTRACT._TITLE_', 'RESCHEDULE_PROJECT.NEXT_CTA._CONTRACT_', 'reschedule-project--choose-contract');
            this.editContractStep = this._buildStep('RESCHEDULE_PROJECT.EDIT_CONTRACT._TITLE_', 'RESCHEDULE_PROJECT.NEXT_CTA._EDIT_CONTRACT_', 'reschedule-project--edit-contract', () => {
                const editContractChoice = this.RescheduleProjectService.getEditContractChoice();
                return editContractChoice !== this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.noChanges;
            });

            this.sendNotificationStep = this._buildStep('RESCHEDULE_PROJECT.SEND_NOTIFICATION._TITLE_', 'RESCHEDULE_PROJECT.NEXT_CTA._SEND_NOTIFICATION_', 'reschedule-project--send-notification', () => {
                const editContractChoice = this.RescheduleProjectService.getEditContractChoice();
                const selectedFiles = Object.values(this.RescheduleProjectService.selectedFiles);
                return (!selectedFiles.length || selectedFiles.every(value => !value)) &&
                       (!editContractChoice || editContractChoice === this.RescheduleProjectService.CHOOSE_CONTRACT_TYPES.noChanges) &&
                        this.workspace.hasValidRecipient();
            });

            if (this.workflow) {
                this.steps.push(this.workflowStep);
            }

            if (this.payableFiles && this.payableFiles.length) {
                this.steps.push(this.paymentsStep);
            }

            if (this.contractFiles && this.contractFiles.length) {
                this.steps.push(this.chooseContractStep);
                this.steps.push(this.editContractStep);
            }

            this.steps.push(this.sendNotificationStep);
        }

        update(type, value) {
            this.projectData[type] = value;
        }

        onDateTimeSelected(changes) {
            this._.each(changes, (value, type) => {
                this.update(type, value);
            });
        }

        setInitialTimezone() {
            const timezone = this.projectData && this.projectData.event_timezone ? this.projectData.event_timezone : this.getDefaultTimezone();
            this.timezone = this.TimezoneService.translateFromServer(timezone);
        }

        getDefaultTimezone() {
            return this.TimezoneService.translateFromServer(this.CompaniesManager.getCurrCompany().default_timezone) || this.timezones[0];
        }

        setTimezone(timezone) {
            this.timezone = timezone.timezone;
            const translatedTimezone = this.TimezoneService.translateToServer(this.timezone);
            this.update('event_timezone', translatedTimezone);
        }

        toggleDetachWorkflow() {
            this.detachWorkflow = !this.detachWorkflow;
        }

        filterFiles() {
            const files = this.workspace.workspace_files;
            this.payableFiles = files.filter(file => file.isSent() && file.hasPayments() && !file.isComplete());
            this.contractFiles = files.filter(file => file.isSent() && file.hasAgreement());
        }

        onSendNotificationReject() {
            this.hide();
            this.saveChanges().then(() => {
                this.close(false);
            });
        }

        onStepComplete() {
            const nextStep = this.getNextVisibleStep();
            this.AnalyticsService.trackClick(this, this.$translate.instant(this.nextStepTitle), {source: this.source});
            if (nextStep) {
                this._switchStep(nextStep);
            } else {
                this.hide();

                const hasValidRecipient = this.workspace.hasValidRecipient();
                const finishPromise = hasValidRecipient ? this.sendEmail() : this.saveChanges();

                finishPromise.then(() => {
                    if (hasValidRecipient) {
                        this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.reschedule_send_mail, {source: this.source});
                    }
                    this.close(hasValidRecipient);
                }).catch(() => {
                    this.show();
                });
            }
        }

        _switchStep(step) {
            this.$('.nx-modal__content').removeClass(this.currentStep.widthClass);
            this.currentStepIndex = this.steps.indexOf(step);
            this.currentStep = step;
            this.$('.nx-modal__content').addClass(this.currentStep.widthClass);

            this.setNextStepTitle();
        }

        previous() {
            this._switchStep(this.getPreviousVisibleStep());
        }

        close(emailSent) {
            this._cleanup(this.RescheduleProjectService.getUnselectedFiles(this.payableFiles));
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.reschedule_flow_done, {source: this.source});
            this.$modalInstance.close(emailSent);
        }

        dismiss() {

            if (this.RescheduleProjectService.hasCreatedContract()) {
                // this is stupid but contracts are saved on destroy
                this.RescheduleProjectService.getCreatedContract().deleteWorkspaceFile();
            }

            this._cleanup(this.RescheduleProjectService.getNewlyEditableFiles());
            this.AnalyticsService.trackClick(this, this.AnalyticsService.analytics_events.reschedule_flow_cancel, {source: this.source});
            this.$modalInstance.dismiss();
        }

        setNextStepTitle() {
            const nextStep = this.getNextVisibleStep();
            if (nextStep) {
                this.nextStepTitle = nextStep.ctaTitle;
            } else if (!this.workspace.hasValidRecipient()) {
                this.nextStepTitle = 'FREQUENT_BUTTONS._SAVE_AND_EXIT_';
            } else {
                this.nextStepTitle = 'FREQUENT_BUTTONS._CONTINUE_';
            }
        }

        getNextVisibleStep() {
            return this._getStep(1);
        }

        getPreviousVisibleStep() {
            return this._getStep(-1);
        }

        _getStep(operator) {
            let nextIndex = this.currentStepIndex + operator;

            // out of range
            if (nextIndex < 0 || nextIndex === this.steps.length) {
                return null;
            }

            let nextStep = this.steps[nextIndex];
            while (nextStep && !nextStep.shouldShow()) {
                nextIndex += operator;
                nextStep = this.steps[nextIndex];
            }

            return nextStep;
        }

        _buildStep(title, ctaTitle, widthClass, shouldShow = (() => true)) {
            return {
                title,
                ctaTitle,
                shouldShow,
                widthClass
            };
        }

        _cleanup(files) {
            files.forEach(f => f.discardFileDraft());
            this.RescheduleProjectService.reset();
        }

        saveChanges() {

            let updateProjectDatePromise = this.$q.when();
            if (this.didProjectDateChanged()) {
                this.projectModel.updateEventDetails(this.projectData, true);
                updateProjectDatePromise = this.EventsManager.updateEventDetails(this.projectModel);
            }

            const updateWorkflowPromise = this.detachWorkflow ? this.WorkflowManager.removeWorkflowInstanceFromWorkspace(this.workspaceId, this.workflow._id) : this.$q.when();
            return this.$q.all([updateProjectDatePromise, updateWorkflowPromise]);
        }

        didProjectDateChanged() {
            return PROJECT_DATE_FIELDS.some(field => this.projectData[field] !== this.projectModel[field]);
        }

        buildEmail(filesIds) {

            const projectDateChanged = this.didProjectDateChanged();

            const subject = `${projectDateChanged ? 'Reschedule Project' : 'Project Updates'}: ${this.projectData.event_name}`;
            const originalDate = this._getDateString(this.projectModel);
            const newDate = this._getDateString(this.projectData);

            const projectDateHtml = projectDateChanged ?
                `<strong>Original Date:</strong> ${originalDate || 'N/A'}<br />
                 <strong>New Date:</strong> ${newDate || 'N/A'}` :
                `<strong>Project Date:</strong> ${originalDate || 'N/A'}`;

            const emailBodyHtml = projectDateChanged ?
                'I have rescheduled your project to a new date. Please review the details below.' :
                `Please review the details of your project${filesIds.length ? ' and review the updated files below' : ''}.`;

            return {
                subject,
                htmlBody: `
                    <p>Hi,</p>
                    <p><br /></p>
                    <p>${emailBodyHtml}</p>
                    <p><br /></p>
                    <strong>Project name:</strong> ${this.UIUtils.sanitize(this.projectData.event_name)} <br />
                    ${projectDateHtml}
                    <p></p>
                    Warmly,
                    <br />
                    ${this.UIUtils.sanitize(this.user.full_name)}
                `
            };
        }

        show() {
            this.hideModal = false;
        }

        hide() {
            this.hideModal = true;
        }

        sendEmail() {
            const fileIds = this.RescheduleProjectService.getFilesToReview(this.payableFiles).map(f => f._id);
            const {subject, htmlBody} = this.buildEmail(fileIds);
            const config = this.WorkspaceFileService._emailEditorConfig(null, this.user, null, this.workspace, true);
            config.sendLabel = 'RESCHEDULE_PROJECT._SEND_EMAIL_LABEL_';
            config.editorType = 'reschedule project';

            const options = {
                users: this.workspace.usersWithoutTeamMembers(),
                subject: subject,
                body: htmlBody,
                senderFunction: this._getMultipleFilesSendFunction(fileIds),
                config: config,
                previewFunction: this._getPreviewFunction(fileIds),
                additionalClass: ' reschedule-project__reveal-modal'
            };

            return this.WorkspaceFileService.showEmailEditorModal(options);
        }

        _getMultipleFilesSendFunction(fileIds) {
            return (props) => {

                var users = props.users;
                var subject = props.subject;
                var body = props.body;
                var params = props.params;
                var attachedFiles = props.attachedFiles;

                const data = {
                    workspace_id: this.workspace._id,
                    file_ids: fileIds,
                    recipients_ids: users.map(user => user._id),
                    email: {
                        subject: subject,
                        html_body: body,
                        email_type: 'proposal'
                    },
                };

                return this.saveChanges().then(() => {
                    return this.PendingTasksManager.createPendingTask(this.PendingTasksManager.pendingTasksTypes.sendRescheduleProjectEmail, data);
                }).then((taskData) => {
                    return taskData;
                });
            };
        }

        _getPreviewFunction(fileIds) {
            return (user, subject, body, params, attachedFiles) => {
                return this.EmailPreviewManager.getRescheduleProjectEmailPreview(user, subject, body, this.workspace._id, fileIds);
            };
        }

        _getDateString(project) {
            let dateString = "";

            if (!project.event_date) {
                return dateString;
            }

            dateString = this.$filter('hbdate')(project.event_date);

            if (project.event_time_start) {
                dateString += ` at ${this.UIUtils.sanitize(project.event_time_start)}`;
            }

            const timezone = this.TimezoneService.translateFromServer(project.event_timezone);
            dateString += ` ${timezone}`;

            if (project.event_end_date) {
                dateString += ` - ${this.$filter('hbdate')(project.event_end_date)}`;

                if (project.event_time_end) {
                    dateString += ` at ${this.UIUtils.sanitize(project.event_time_end)}`;
                }

                dateString += ` ${timezone}`;
            }

            return dateString;
        }
    };
}());
