common_module.constant('ToastMappings', {
    AchPush: {
        templateUrl : 'angular/app/modules/core/features/workspace_file/ach_push_toast/ach_push_toast_template.html',
        controller: 'AchPushToastController as achPushToastVm'
    },
    RescheduleSuccess: {
        templateUrl : 'angular/app/modules/core/features/reschedule_project_modal/reschedule_project_success_toast_template.html',
        controller: 'RescheduleProjectSuccessToastController as rescheduleSuccessToastVm'
    },

    CanvaInProgress: {
        templateUrl : 'angular/app/modules/core/features/reschedule_project_modal/reschedule_project_success_toast_template.html',
        controller: 'RescheduleProjectSuccessToastController as rescheduleSuccessToastVm'
    }

});
