(function () {
    "use strict";

    // @ngInject
    function WorkspacePaymentsControllerCtor($scope, $stateParams, WorkspacesManager, $injector, WorkspaceFileService, Enums, RepositoryService,
                                             AppStates, UsersManager, FlowService, $translate, FlowsBetaUserTypeService, ModalService, EventsManager,
                                             TemplatesManager, CompaniesManager, DeviceService, ReactModalService, AnalyticsService, StatsigService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'WorkspacePaymentsController';

        this.currUser = UsersManager.getCurrUser();
        this.isClient = this.currUser.isClient();
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspacesManager = WorkspacesManager;
        this.AppStates = AppStates;
        this.FlowService = FlowService;
        this.$translate = $translate;
        this.FlowsBetaUserTypeService = FlowsBetaUserTypeService;
        this.ModalService = ModalService;
        this.TemplatesManager = TemplatesManager;
        this.Enums = Enums;
        this.CompaniesManager = CompaniesManager;
        this.DeviceService = DeviceService;
        this.ReactModalService = ReactModalService;
        this.AnalyticsService = AnalyticsService;

        this.isInWorkspaceView = true;
        this.gotPayments = false;

        this.workspaceId = $stateParams.workspace_id;
        var eventId = $stateParams.event_id;
        this.project = EventsManager.getEvent(eventId);

        RepositoryService.persistentPut('event:' + eventId, {tabToOpen: Enums.workspaceTabTypes.payments, latestWsId: this.workspaceId});

        this.workspace = WorkspacesManager.getWorkspace(this.workspaceId);
        this.register(this.workspace, 'success', this.gotWorkspace);

        this.sortedFiles = [];

        WorkspacesManager.getPaymentsForWorkspace(this.workspace, this.workspaceId).then(
            function success(){
                this.gotPayments = true;
            }.bind(this)
        );

        this.emptyStateData = this._getEmptyStateData();

        this.workspacePaymentsTabVariant = '';
        StatsigService.isGateEnabled('ng2react_workspace_payments_tab').then(function(enabled){
            this.workspacePaymentsTabVariant = enabled ? 'react' : 'angular';
        }.bind(this));
    }


    Controllers.WorkspacePaymentsController = Class(Controllers.BaseController, {

        constructor: WorkspacePaymentsControllerCtor,

        _getEmptyStateData: function _getEmptyStateData() {
            var image = 'v1562085519/empty_states/payments/cash-register';
            var title = 'WORKSPACE.PAYMENTS.PAYMENT_AWARENESS_EMPTY_STATE._TITLE_';
            var text = (this.FlowsBetaUserTypeService.hasNewFlowExp) ? 'WORKSPACE.PAYMENTS.PAYMENT_AWARENESS_EMPTY_STATE._DESC_FLOWS_' : 'WORKSPACE.PAYMENTS.PAYMENT_AWARENESS_EMPTY_STATE._DESC_';
            var ctaText = 'WORKSPACE.PAYMENTS.PAYMENT_AWARENESS_EMPTY_STATE._CREATE_INVOICE_';
            var ctaCallback = function ctaCallback() {
                if (this.FlowsBetaUserTypeService.hasNewFlowExp) {
                    this.paymentAwarenessCtaCallBack();
                } else {
                    this.$parent.workspaceVm.createFileByType('invoice');
                }
            };

            var analyticsData = {
                'eventName' : 'click: create invoice',
                'data': {
                    'type': 'payment empty state',
                    'unique': 'empty state primary cta'
                }
            }

            return {
                image: image,
                title: this.$translate.instant(title),
                text: this.$translate.instant(text),
                ctaText: this.$translate.instant(ctaText),
                ctaCallback: ctaCallback.bind(this),
                analyticsData: analyticsData
            }
        },

        gotWorkspace: function gotWorkspace() {
            var filteredFiles = (this.workspace.workspace_files || []).filter(this.filterFiles);
            var filteredFlows = (this.workspace.flows || []).filter(this.filterFlows);
            this.sortedFiles = filteredFiles.concat(filteredFlows).sort(function(a,b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            this.AnalyticsService.trackLoad(this, 'workspace payments', {
                files: filteredFiles.length,
                flows: filteredFlows.length,
            });
        },
        
        filterFiles: function filterFiles(currFile) {
            return (currFile.isSent() && currFile.hasPayments()) || currFile.hasPaidPayments();
        },

        filterFlows: function filterFlows(currFlow) {
            return currFlow.status !== 'draft' && currFlow.has_invoice;
        },

        isOwnerMode: function isOwnerMode(flow) {
            // logic copied from workspace_file_model
            return flow.owner_id === this.currUser._id ||
                (this.currUser.isVendor() && this.currUser.company &&
                    this.currUser.company._id === flow.company_id &&
                    this.currUser.hasCompanyAdminPermissions());
        },

        goToFlow: function ($event, flow) {
            this.FlowService.goToFlowByUser(flow, this.currUser);
        },

        goToFileView: function goToFileView($event, workspaceFile){
            $event.preventDefault();
            this.WorkspaceFileService.gotoWorkspaceFileView(workspaceFile);
        },

        showDraftWarning: function showDraftWarning(currFile){
            return currFile.isDraftEditable() && !currFile.isFirstDraft() && currFile.isOwnerMode();
        },

        paymentAwarenessCtaCallBack: function paymentAwarenessCtaCallBack(){
            if (this.DeviceService.nxSmallBreakpoint()) {
                this.ReactModalService.openBlockFlowsModal();
            } else {
                this.TemplatesManager.createFlowInstance(this.Enums.flowTemplateIds.blank_invoice, this.workspaceId)
                    .then(function success(flowInstance){
                        this.FlowService.goToFlow(flowInstance.data._id);
                    }.bind(this))
                    .catch(function error(err){
                        console.error('error creating invoice from workspace', err);
                    }.bind(this));
            }
        }
    });
}());
