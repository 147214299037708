(function () {
    "use strict";
    
    // @ngInject
    function FeatureRestrictionServiceCtor($injector, CompaniesManager, UsersManager, FeaturesService, ModalService,
                                           ReactModalService, AnalyticsService, moment, InitialAppLoadParamsService,
                                           Gon, $stateParams, WorkspacesManager, DatadogRUMService, $location) {
        this.$injector = $injector;
        this.CompaniesManager = CompaniesManager;
        this.FeaturesService = FeaturesService;
        this.UsersManager = UsersManager;
        this.ModalService = ModalService;
        this.ReactModalService = ReactModalService;
        this.AnalyticsService = AnalyticsService;
        this.InitialAppLoadParamsService = InitialAppLoadParamsService;
        this.moment = moment;
        this.$stateParams = $stateParams;
        this.WorkspacesManager = WorkspacesManager;
        this.Gon = Gon;
        this.isFetchFeaturePlanLoading = false;
        this.hasShownCanceledAccountModal = false;
        this.noModalOnLoad = InitialAppLoadParamsService.getParam('noModalOnLoad') === '1';
        this.DatadogRUMService = DatadogRUMService;
        this.$location = $location;
    }

    Services.FeatureRestrictionService = Class(() => {

        return {
            constructor: FeatureRestrictionServiceCtor,

            features: {
                advancedReports: 'advanced_reports',
                calendly: 'calendly',
                customFonts: 'custom_fonts',
                expenses: 'expenses',
                facebookLeads: 'facebook_leads',
                multiBrands: 'multi_brands',
                profitLoss: 'profit_loss',
                quickbooks: 'quickbooks',
                removePoweredBy: 'remove_powered_by',
                scheduler: 'scheduler',
                standardReports: 'standard_reports',
                socialIntegrations: 'social_integrations',
                teamMembers: 'team_members',
                workflows: 'workflows',
                zoom: 'zoom',
                canva: 'canva',
                bookkeeperAccess: 'bookkeeper_access',
            },

            updateLastFetchDate() {
                this.lastFetchDate = new Date();
            },
            
            updateFeatureRestrictions() {
                const user = this.UsersManager.getCurrUser();
                const featurePlan = user.account && user.account.featurePlan;
                if (!featurePlan) {
                    return;
                }
                
                this.featureRestrictions = featurePlan.reduce((restrictions, featureVariation) => {
                    const {key, variation} = featureVariation;
                    try {
                        if (variation === 'on') {
                            restrictions[key] = false;
                            return restrictions;
                        }

                        if (variation === 'off') {
                            restrictions[key] = true;
                            return restrictions;
                        }

                        if (key === this.features.teamMembers) {
                            let isRestricted = false;

                            if (!isNaN(+variation)) {
                                const company = this.CompaniesManager.getCurrCompany();
                                const enabledTeamMembers = company.getEnabledTeamMembers();
                                const teamMembersCount = (enabledTeamMembers && enabledTeamMembers.length) || 0;

                                isRestricted = teamMembersCount >= +variation;
                            }

                            restrictions[key] = isRestricted;
                            return restrictions;
                        }

                        if (key === this.features.scheduler) {
                            let isRestricted = false;

                            if (!isNaN(+variation)) {
                                const activeSessions = user.getActiveSchedulingSessions();
                                const activeSessionsCount = (activeSessions && activeSessions.length) || 0;

                                isRestricted = activeSessionsCount >= +variation;
                            }

                            restrictions[key] = isRestricted;
                            return restrictions;
                        }
                    } catch (e) {
                        this.DatadogRUMService.addError(e, {msg: 'Feature restrictions update failure' , featureVariation, user_id: user._id });
                    }
                    restrictions[key] = true;
                    return restrictions;
                }, {});
            },

            getFeatureVariation(feature) {
                const user = this.UsersManager.getCurrUser();
                const featurePlan = user.account.featurePlan;
                
                if (!featurePlan) {
                    return;
                }
                
                const featureData = featurePlan.find(_feature => _feature.key === feature);
                
                if (featureData) {
                    return featureData.variation;
                }
            },
            
            isFeatureRestricted(feature) {
                if (!this.featureRestrictions) {
                    return false;
                }
                
                this.checkIfShouldRefetchFeaturePlan();
                
                if (this.featureRestrictions[feature] === undefined) {
                    return false;
                }
                
                return this.featureRestrictions[feature];
            },
            
            handleRestrictedFeatureClick(feature, data) {
                const user = this.UsersManager.getCurrUser();
                
                this.AnalyticsService.trackClick(
                    this,
                    this.AnalyticsService.analytics_events.blocked_feature,
                    {
                        current_plan: user.getPlanType(),
                        feature
                    }
                );
                
                if (user.isAppSubscription()) {
                    this.ReactModalService.openAppStoreModal();
                } else {
                    this.ReactModalService.openUpgradePlanModal(data);
                }
            },

            checkIfBlockedAccess(params) {
                if (this.Gon.force_bypass_blocking_modals) {
                    return false;
                }
                
                const user = this.UsersManager.getCurrUser();
                const company = user.company;
                const actionType = params && params.actionType;
                
                if (actionType === 'load' && this.noModalOnLoad) {
                    return false;
                }

                if (this.$location.url().includes('OAuth2Callback')) {
                    return false;
                }

                if (this.$stateParams.noModalOnLoad && this.$stateParams.noModalOnLoad === 1) {
                    return false;
                }

                // Allow collaborator to send email from workspace
                const workspaceId = this.$stateParams.workspace_id;
                if (workspaceId && params && params.source === 'send_email') {
                    const workspace = this.WorkspacesManager.getWorkspace(workspaceId);
                    if (workspace && company._id !== workspace.creator_company_id) {
                        return false;
                    }
                }
                
                if (user.account && !user.account.trial) {
                    return false;
                }

                if (user.isTrialEnded() && company && company.membership_canceled) {
                    this.ReactModalService.openCanceledAccountDialog();
                    return true;
                }
                
                if (user.is_disabled) {
                    this.ReactModalService.openDisabledTeamMemberDialog();
                    return true;
                }
                
                if (company && company.archived) {
                    this.ReactModalService.openDisabledCompanyDialog();
                    return true;
                }

                if (user.hasTrialExpiredDueToNoPayment()) {
                    if(!user.isBookkeeperMode()) {
                        this.ModalService.openTrialExpiredModal();
                    }
                    return true;
                }

                return false;
            },

            checkIfAccountCanceledOnCompanyChange() {
                const user = this.UsersManager.getCurrUser();
                const company = user.company;
                
                const shouldShowCancelAccountModal =
                    user.isTrialEnded() &&
                    company && company.membership_canceled &&
                    !this.hasShownCanceledAccountModal &&
                    !this.noModalOnLoad;
                
                if (shouldShowCancelAccountModal) {
                    this.ReactModalService.openCanceledAccountDialog();
                    this.hasShownCanceledAccountModal = true;
                    return true;
                }
            },

            checkIfShouldRefetchFeaturePlan() {
                const shouldRefetch =
                    this.lastFetchDate && 
                    this.moment().diff(this.lastFetchDate, 'minutes') >= 30 &&
                    !this.isFetchFeaturePlanLoading;
                
                if (shouldRefetch) {
                    this.AccountsManager = this.AccountsManager || this.$injector.get('AccountsManager');
                    this.AccountsManager.fetchFeaturePlan({ forceFetch: true});
                }
            },
            
            setIsFeaturePlanLoading(isLoading) {
                this.isFetchFeaturePlanLoading = isLoading;
            },

            reset() {
                this.featureRestrictions = null;
            }
        };
    });
}());

