//******************************************************
// A simple service that is used to persist ui-only data.
// Things like the last sorting selection on a screen that was chosen by the user. Or a do not show me again checkboxes.
// In the future history on some drop-downs could be added here too
//******************************************************
(function () {
    "use strict";

    // @ngInject
    function UiPersistenceServiceCtor(UsersManager, PubSubService, $q) {
        this.UsersManager = UsersManager;
        this.$q = $q;
        PubSubService.ventMyBitchUp(this);
        //handle the event of a user logging out and clear the internal cache
        this.UsersManager.on('loggingIn', this.initialize, this);
        this.UsersManager.on('resetPsw', this.initialize, this);
        this.UsersManager.on('loggingOut', this.onLoggingOut, this);
        this.initialize();
    }

    Services.UiPersistenceService = Class(function () {

        return {

            constructor: UiPersistenceServiceCtor,
            /**
             * export all the keys of persistency on the service so they can be used from outside the service
             * IMPORTANT! this enum is used in order to create unique keys.
             * do not delete keys from here unless it's also deleted from the db
             * please from now on make sure the keys are lowerCamelCase
             */
            keys: {
                intro_images_in_file: 'intro_images_in_file',
                pipeline_view: 'pipeline_view',
                pipeline_settings: 'pipeline_settings',
                dontShowIntroAgain: 'dontShowIntroAgain',
                dontShowTooltipAgain2: 'dontShowTooltipAgain2',
                hideUnsentFileWarning: 'hideUnsentFileWarning',
                dontShowFileCommentsTooltip: 'dontShowFileCommentsTooltip',
                dontShowOpportunitiesGuidelines: 'dontShowOpportunitiesGuidelines',
                workspaceType: 'workspaceType',
                calendarView: 'calendarView',
                dontShowCalIntroAgain: 'dontShowCalIntroAgain',
                hideOnboardingAddPackage: 'hideOnboardingAddPackage',
                hideOnboardingPaymentSchedule: 'hideOnboardingPaymentSchedule',
                hideOnboardingAgreement: 'hideOnboardingAgreement',
                coreControllerPersistence: 'coreControllerPersistence',
                leftPanelView: 'leftPanelView',
                hideExampleTemplateSuggestion: 'hideExampleTemplateSuggestion',
                contactFormIntro: 'contactFormIntro',
                hideAutoExpirationIntro: 'hideAutoExpirationIntro',
                hideBrochureV3Intro: 'hideBrochureV3Intro',
                last_event_visited: 'last_event_visited', //this code is used on the server side. it is added here just to keep track of all the ui persistency codes
                emailEditor: 'emailEditor',
                showExampleProposal: 'showExampleProposal',
                defaultTabOnWorkspaceView: 'defaultTabOnWorkspaceView',
                showLeadsReportIntro: 'showLeadsReportIntro',
                contactFormAsideMenu: 'contactFormAsideMenu',
                showLeadsReportNewBadge: 'showLeadsReportNewBadge',
                hideUnloadExampleProposal: 'hideUnloadExampleProposal',
                contactsView: 'contactsView',
                loginlessIntro: 'loginlessIntro',
                brochureQuickSend: 'brochureQuickSend',
                quickbooksIntro: 'quickbooksIntro',
                reportsSummary: 'reportsSummary',
                reportsLeads: 'reportsLeads',
                updatedReportSummaryNotification: 'updatedReportSummaryNotification',
                onBoardingOverview: 'onBoardingOverview',
                spacesPopupViewed: 'spacesPopupViewed',
                pipelineContainer: 'pipelineContainer',
                mobileAppIntro: 'mobileAppIntro',
                trialerLeftFile: 'trialerLeftFile',
                leadSourceModalIntro: 'leadSourceModalIntro',
                leadSourceTooltip: 'leadSourceTooltip',
                hideQBSyncModal: 'hideQBSyncModal',
                topNavIntro: 'topNavIntro',
                tasks_mgmt_footer: 'tasks_mgmt_footer',
                workflowApprovalDependedStep: 'workflowApprovalDependedStep',
                dontShowGoogleSpamConfirmModal: 'dontShowGoogleSpamConfirmModal',
                workflowTemplateHasActiveClonedInstances: 'workflowTemplateHasActiveClonedInstances',
                createdWorkflow: 'createdWorkflow',
                asideDrawerTemplatesNotification: 'asideDrawerTemplatesNotification',
                asideDrawerFileNotification: 'asideDrawerFileNotification',
                notificationsTooltip: 'notificationsTooltip',
                notificationsShowMarkAsReadEmptyState: 'notificationsShowMarkAsReadEmptyState',
                brandColorTooltip: 'brandColorTooltip',
                notesPopover: 'notesPopover',
                peopleSuggestionsBlacklist: 'peopleSuggestionsBlacklist',
                productExplanationBanners: 'productExplanationBanners',
                setupGuideCompletedSteps: 'setupGuideCompletedSteps',
                setupGuideVersion: 'setupGuideVersion',
                setupGuideCompletedAllSteps: 'setupGuideCompletedAllSteps',
                setupGuideModalCompletedSteps: 'setupGuideModalCompletedSteps',
                setupGuideModalStepsOrder: 'setupGuideModalStepsOrder',
                contactFormNotInstalledBanner: 'contactFormNotInstalledBanner',
                topNavMenuOptionsOrder: 'topNavMenuOptionsOrder',
                fileCreatedFromTemplatesTooltip: 'fileCreatedFromTemplatesTooltip',
                activityPreferencesBanner: 'activityPreferencesBanner',
                showInviteModalActiveProject: 'showInviteModalActiveProject',
                dontShowDepricationFileTemplatesBanner: 'dontShowDepricationFileTemplatesBanner',
                showLeadSourceToast: 'showLeadSourceToast',
                showAchPushToast: 'showAchPushToast',
                showAchPushToastLastFileId: 'showAchPushToastLastFileId',
                mobileQuizOnboardingCompleted: 'mobileQuizOnboardingCompleted',
                mobileQuizOnboardingStarted: 'mobileQuizOnboardingStarted',
                editableAgreement: 'editableAgreement',
                showHomeWelcomeModal: 'showHomeWelcomeModal',
                showNewInvoiceToast: 'showNewInvoiceToast',
                openFirstClient: 'openFirstClient',
                onboardingQuizAnswers: 'onboardingQuizAnswers',
                referralSuggestionsBlacklist: 'referralSuggestionsBlacklist',
                membershipCancellationViewed: 'membershipCancellationViewed',
                recurringPaymentsIntro: 'recurringPaymentsIntro',
                smartSuggestions: 'smartSuggestions',
                uploadClientsCSV: 'uploadClientsCSV',
                flowsOnboarding: 'flowsOnboarding',
                hideFlowsFoldersBanner: 'hideFlowsFoldersBanner',
                clientFlowModal: 'clientFlowModal',
                homeToolsSortManager: 'homeToolsSortManager',
                shareasalePixel: 'shareasalePixel',
                accountSetupTilePrompt: 'accountSetupTilePrompt',
                accountSetupOffer: 'accountSetupOffer',
                hideExpiredTrialModal: 'hideExpiredTrialModal',
                brochureNew: 'brochureNew',
                pipelineOnboarding: 'pipelineOnboarding',
                hiddenHomeBannerThemes: 'hiddenHomeBannerThemes',
                ContactsPreferredPopup: 'ContactsPreferredPopup',
                topNavMultiBrand: 'topNavMultiBrand',
                showLeadSourceProTip: 'showLeadSourceProTip',
                websitePlatformHash: 'websitePlatformHash',
                setupGuideModalLastOpened: 'setupGuideModalLastOpened',
                templatesEditorDrawerPopover: 'templatesEditorDrawerPopover',
                setupGuideTopNavAnimate: 'setupGuideTopNavAnimate',
                homeOnboardingCard: 'homeCardSetupGuideStreamlined',
                isSeenGetPaidWizard: 'isSeenGetPaidWizard',
                yearLastSawBirthdayModal: 'yearLastSawBirthdayModal',
                gmailRevoked: 'gmailRevoked',
                contactFormDynamicOnboarding: 'contactFormDynamicOnboarding',
                pipelineLeadsInsights: 'pipelineLeadsInsights',
                instantDeposits: 'instantDeposits',
                MwebSetupGuide: 'mwebSetupGuide',
                MwebBannerGoToDesktop: 'mwebBannerGoToDesktop',
                MwebBannerDownloadApp: 'mwebBannerDownloadApp',
                upcomingPaymentsModal: 'upcomingPaymentsModal',
                animateSendTestFileButton: 'animateSendTestFileButton',
                templatesExplainModal: 'templatesExplainModal',
                templatesLibraryViewOptions: 'templatesLibraryViewOptions',
                promoteInstantDepositAutoToggleState: 'promoteInstantDepositAutoToggleState',
                bulkEmailIntro: 'bulkEmailIntro',
                // showHomeCoronaBanner: 'showHomeCoronaBanner',
                showAddSchedulingLinkReminder: 'showAddSchedulingLinkReminder',
                schedulingExposureCounter: 'schedulingExposureCounter',
                schedulingExposurePocVariation: 'schedulingExposurePocVariation',
                automationsFeatureOptIn: 'automationsFeatureOptIn',
                createFlowTopNav: 'createFlowTopNav',
                totalTransactionAmount: 'totalTransactionAmount',
                selectedTemplatePanel: 'selectedTemplatePanel',
                otamMigration: 'otamMigration',
                viewOnlyToInvoiceTipShowCount: 'viewOnlyToInvoiceTipShowCount',
                tourVisits: 'tourVisits',
                dismissedServiceSelectionSuperpowerModal: 'dismissedServiceSelectionSuperpowerModal',
                firstPayableFlowWithNoBankToastViewed: 'firstPayableFlowWithNoBankToastViewed',
                loginTwoFactorAuthBannerInteracted: 'loginTwoFactorAuthBannerInteracted',
                isFoldersOpenInCreateFlowModal: 'isFoldersOpenInCreateFlowModal',
                isVisitInMyTemplates: 'isVisitInMyTemplates',
                myTemplatesLayoutViewMode: 'myTemplatesLayoutViewMode',
                secondFolderTooltip: 'secondFolderTooltip',
                hasDownloadedInvoiceCSV: 'hasDownloadedInvoiceCSV',
                showAddBankAccountBanner: 'showAddBankAccountBanner',
                dismissedDisputeWarning: 'dismissedDisputeWarning',
                publishSessionDisabled: 'publishSessionDisabled',
                calendlyDisconnectedMessage: 'calendlyDisconnectedMessage',
                galleryPushToPros: 'galleryPushToPros',
                wasInvoiceSent: 'wasInvoiceSent',
                hasSeenTeamMembersSplashModal: 'hasSeenTeamMembersSplashModal',
                showInvoiceAwareness: 'showInvoiceAwareness',
                dismissedVerticalTemplatesRecommendations: 'dismissedVerticalTemplatesRecommendations',
                dismissedPushToGalleryBanner: 'dismissedPushToGalleryBanner',
                mobileInvoiceTour: 'mobileInvoiceTour',
                mobileAppReview: 'mobileAppReview',
                mobileDidSeeAIResponse: 'mobileDidSeeAIResponse',
                notesSmartFields: 'notesSmartFields',
                showEnableCardOnFileBannerLegacyFile: 'showEnableCardOnFileBannerLegacyFile',
                taxEntityUpdated: 'taxEntityUpdated',
                isClickBackInBuilder: 'isClickBackInBuilder',
                schedulerFirstTimeExperienceQuestionsBanner: 'schedulerFirstTimeExperienceQuestionsBanner',
                schedulerFirstTimeExperienceRequireToPay: 'schedulerFirstTimeExperienceRequireToPay',
                schedulerFirstTimeExperienceNewBlockOverlay: 'schedulerFirstTimeExperienceNewBlockOverlay',
                talBucketSurveyModal: 'talBucketSurveyModal',
                dismissedSchedulerBlockBannerInTool: 'dismissedSchedulerBlockBannerInTool',
                intentOnboardingIsCompleted: 'intentOnboardingIsCompleted',
                intentOnboardingIsInInvoiceFlow: 'intentOnboardingIsInInvoiceFlow',
                intentOnboarding: "intentOnboarding",
                schedulerOnboardingSessionPrice: 'schedulerOnboardingSessionPrice',
                referralGiftEntryPoint: 'referralGiftEntryPoint',
                goToBankInfoClicked: 'goToBankInfoClicked',
                paymentSurvey: 'paymentSurvey',
                emailComposerDeliverabilityEducationTourSeen: 'emailComposerDeliverabilityEducationTourSeen',
                instantBranding: 'instantBranding',
                pipelineContactsBannerShown: 'pipelineContactsBannerShown',
                hideForm1099DownloadBanner: 'hideForm1099DownloadBanner',
                hideLaFireBanner: 'hideLaFireBanner',
                oneHomeZeroStates: 'oneHomeZeroStates',
                draftInvoiceExistsForInvoiceReport: 'draftInvoiceExistsForInvoiceReport',
                clientDismissedCPPDisclaimerBanner: 'clientDismissedCPPDisclaimerBanner',
                autoInsertAISeenCount: 'autoInsertAISeenCount'
            },
            /**
             * handler for the logging out event
             */
            onLoggingOut: function onLoggingOut() {
                this.clearAll();
            },

            updateSuccess: function updateSuccess() {
                if (this.user.user_ui_persistences && this.user.user_ui_persistences.length) {
                    this.user.user_ui_persistences.forEach(function (persistenceItem) {
                        this.persistenceHash[persistenceItem.key] = persistenceItem.value;
                    }.bind(this));
                }
            },

            refetchUiPersistence: function refetchUiPersistence() {
                return this.UsersManager.refetchUiPersistence(this.user).then(function success() {
                    this.updateSuccess();
                }.bind(this));
            },

            /**
             * getUiPersistence get the ui-persistence data that is associated with the string key
             * @param key {string} they key in which the ui-persistence data is associated with
             * @param key {object} defaultHash this hash will be returned in the case where we dont have any persistence yet
             * @param defaultHash {object} does not have to be supplied. it is continent on the first time where there is no set key to give a default that will return when there is non stored
             * @returns {Object} if there is a JSON object associated with that key, otherwise returns undefined
             */
            getUiPersistence: function getUiPersistence(key, defaultHash) {
                var returnedValTemp, returnedVal, copyOfElementToStore;
                var valueStoredOnKey = this.persistenceHash[key];
                if (valueStoredOnKey !== undefined && valueStoredOnKey !== null) {
                    returnedValTemp = valueStoredOnKey;
                } else {
                    returnedValTemp = defaultHash;
                    //store this last default value that was returned to support getting default on another view after a default was added
                    copyOfElementToStore = angular.copy(returnedValTemp);
                    this.persistenceHash[key] = copyOfElementToStore;
                }

                //we want to save a copy of the given element so that the angular.equals will not use the === for object comparison
                returnedVal = angular.copy(returnedValTemp);

                return returnedVal;
            },

            getOwnerUiPersistence: function getOwnerUiPersistence(key, defaultHash) {
                const ownerPersistenceHash = [];
                if (this.user.company.owner.user_ui_persistences && this.user.company.owner.user_ui_persistences.length) {
                    this.user.company.owner.user_ui_persistences.forEach(function (persistenceItem) {
                        ownerPersistenceHash[persistenceItem.key] = persistenceItem.value;
                    }.bind(this));
                }

                var returnedValTemp, returnedVal, copyOfElementToStore;
                var valueStoredOnKey = ownerPersistenceHash[key];
                if (valueStoredOnKey !== undefined && valueStoredOnKey !== null) {
                    returnedValTemp = valueStoredOnKey;
                } else {
                    returnedValTemp = defaultHash;
                    //store this last default value that was returned to support getting default on another view after a default was added
                    copyOfElementToStore = angular.copy(returnedValTemp);
                    ownerPersistenceHash[key] = copyOfElementToStore;
                }

                // we want to save a copy of the given element so that the angular.equals will not use the === for object comparison
                returnedVal = angular.copy(returnedValTemp);

                return returnedVal;
            },

            /**
             * setUiPersistence sets the ui-persistence data for a given key on the user's object the data is persisted to
             * the server
             * @param key {string} a key to assign the data to be persisted. this key needs to be unique. The uniqueness is the
             * responsibility of calling code
             * @param value {Object} a JSON object that will be persisted on the server side on the user's object with the given key.
             */
            setUiPersistence: function setUiPersistence(key, value) {
                var oldValue = this.persistenceHash[key];
                var copyOfElementToStore;

                // optimisation does not persist onboarding steps
                if (oldValue) {
                    // optimisation really persist only if the value is different then we have already in the cache.
                    if (angular.equals(oldValue, value)) {
                        //this value is already persisted no need to save it to the server
                        return;
                    }
                }

                //we want to save a copy of the given element so that the angular.equals will not use the === for object comparison
                copyOfElementToStore = angular.copy(value);
                this.persistenceHash[key] = copyOfElementToStore;
                this.UsersManager.setUiPersistence(this.user, { key: key, value: value });
                this.firePersistenceChanged(key, oldValue, this.persistenceHash[key]);
            },

            /** when using a hash with multiple values - set the inner hash key instantly
             * @param key
             * @param innerKey
             * @param value
             */
            setUiPersistenceInnerKey: function setUiPersistenceInnerKey(key, innerKey, value) {
                var obj = this.getUiPersistence(key, {});
                obj[innerKey] = value;
                this.setUiPersistence(key, obj);
            },

            clearAll: function clearAll() {
                if (this.user) {
                    this.user.offAll(this);
                    this.user = null;
                }
                this.resetPersistenceHash();
            },

            resetPersistenceHash: function resetPersistenceHash() {
                if (this.persistenceHash) {
                    Object.keys(this.persistenceHash).forEach(
                        function (key) {
                            delete this.persistenceHash[key];
                        }.bind(this)
                    );
                } else {
                    this.persistenceHash = {};
                }
            },

            initialize: function initialize(currUser) {
                this.resetPersistenceHash();

                if (this.user) {
                    this.user.offAll(this);
                    this.user = null;
                }
                if (currUser) {
                    this.user = currUser;
                } else {
                    this.user = this.UsersManager.getCurrUser();
                }
                this.user.on('success', this.updateIfUiPersistenceWasFetched, this);
                this.UsersManager.on('loginLess', function onLoginLessSuccess(user) {
                    this.user = user;
                    this.updateSuccess();
                }.bind(this));
                this.updateSuccess();
            },

            firePersistenceChanged: function firePersistenceChanged(key, oldvalue, newvalue) {
                this.trigger('uiPersistencyItemChanged', {
                    keyName: key,
                    oldValue: oldvalue,
                    newValue: newvalue
                });
            },

            updateIfUiPersistenceWasFetched: function updateIfUiPersistenceWasFetched(message, status, fetchedUser) {
                if (fetchedUser && fetchedUser.user_ui_persistences) {
                    this.updateSuccess();
                }
            }
        };
    });
}());
