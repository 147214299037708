(function () {
    "use strict";

    // @ngInject
    function FilesHubServiceCtor(StatsigService, Enums, UsersManager, DeviceService) {

        this.StatsigService = StatsigService;
        this.Enums = Enums;
        this.UsersManager = UsersManager;
        this.DeviceService = DeviceService;

        this.isDesktop = !this.DeviceService.nxSmallBreakpoint();
    }

    Services.FilesHubService = Class(() => {

        return {
            constructor: FilesHubServiceCtor,

            isFilesHubVisible() {
                return new Promise((resolve, reject) => {
                    resolve(this.isDesktop);
                });
            }
        };
    });
}());