(function () {
    'use strict';

    class HomeWelcomeMessageComponent extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor(
            $scope,
            $element,
            $attrs,
            $injector,
            $state,
            moment,
            UsersManager,
            CompaniesManager,
            UIUtils,
            SetupGuideService,
            DoodleService,
            Enums,
            UserService,
            AppStates,
            FeaturesService,
            OtamStatusService,
            AnalyticsService,
            StatsigService,
            _
        ) {
            super($scope, $injector);
            this.__objectType = 'HomeWelcomeMessage';

            this.user = UsersManager.getCurrUser();
            this.company = CompaniesManager.getCurrCompany();
            this.UIUtils = UIUtils;
            this.DoodleService = DoodleService;
            this.SetupGuideService = SetupGuideService;
            this.Enums = Enums;
            this.UserService = UserService;
            this.today = moment().format('ddd, MMM DD, YYYY');
            this.isAmericanUser = true;
            this.$state = $state;
            this.AppStates = AppStates;
            this.FeaturesService = FeaturesService;
            this.OtamStatusService = OtamStatusService;
            this.OtamStatusService.initializeAfterLogin();
            this.AnalyticsService = AnalyticsService;
            this.StatsigService = StatsigService;
            this.isLoading = true;

            this.isNewYearPeriod = function isNewYearPeriod() {
                var beginning = moment('01/01/2021').format('ddd, MMM DD, YYYY');
                var end = moment('01/10/2021').format('ddd, MMM DD, YYYY');
                return moment(this.today).isBetween(beginning, end) && this.user.isCompanyOwner() && this.user.isActivated() && this.moment(this.user.activated_on).isBefore('10/01/2020');
            };

            var showYearReviewDecorations = false;

            const yirPromise = this.StatsigService.isGateEnabled(this.Enums.StatsigFeatureGates.yearInReview2023).then(function (isEnabled) {
                showYearReviewDecorations = this.user.isCompanyOwner() && isEnabled;
            }.bind(this));

            var showBirthdayDecorations = this.UserService.isTodayUsersBirthday(this.user) && this.UserService.isBankAccountTypeIndividual(this.user);
            var showOtamMigration = OtamStatusService.isMigrationEnabledExp() && !OtamStatusService.isMigrated();
            const showOooBanner = !showYearReviewDecorations;

            this.getRandomHolidayDoodleUrl = function getRandomHolidayDoodleUrl() {
                const MAX_ILLUSTRATIONS_NUMBER = 3;
                var randomIllustrationNo = _.random(1, MAX_ILLUSTRATIONS_NUMBER);
                var link = `//res.cloudinary.com/honeybook/image/upload/v1702551581/system_web/home/Holiday_season_doodle${randomIllustrationNo}.png`;
                return link;
            }

            const randomHolidayDoodleUrl = this.getRandomHolidayDoodleUrl();

            var states = {
                morning: {
                    image: {
                        normal: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-morning.svg",
                        winter: "//res.cloudinary.com/honeybook/image/upload/v1545224282/system_web/home/Christmas_doodle_home.svg"
                    },
                    titleTranslationKey: "HOME.STATES.MORNING._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.MORNING._SUBTITLE_"
                },
                afternoon: {
                    image: {
                        normal: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-afternoon.svg",
                        winter: "//res.cloudinary.com/honeybook/image/upload/v1545224282/system_web/home/Christmas_doodle_home.svg"
                    },
                    titleTranslationKey: "HOME.STATES.AFTERNOON._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.AFTERNOON._SUBTITLE_"
                },
                evening: {
                    image: {
                        normal: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-evening.svg",
                        winter: "//res.cloudinary.com/honeybook/image/upload/v1545224282/system_web/home/Christmas_doodle_home.svg"
                    },
                    titleTranslationKey: "HOME.STATES.EVENING._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.EVENING._SUBTITLE_"
                },
                night: {
                    image: {
                        normal: "//res.cloudinary.com/honeybook/image/upload/v1524669636/system_web/home/illustration-night.svg",
                        winter: "//res.cloudinary.com/honeybook/image/upload/v1545224282/system_web/home/Christmas_doodle_home.svg"
                    },
                    titleTranslationKey: "HOME.STATES.NIGHT._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.NIGHT._SUBTITLE_"
                },
                birthday: {
                    image: {
                        normal: "//res.cloudinary.com/honeybook/image/upload/v1565041428/app/birthday.svg",
                        winter: "//res.cloudinary.com/honeybook/image/upload/v1565041428/app/birthday.svg"
                    },
                    titleTranslationKey: "HOME.STATES.HAPPY_BIRTHDAY._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.HAPPY_BIRTHDAY._SUBTITLE_"
                },
                year_review: {
                    image: {
                        normal: "https://res.cloudinary.com/honeybook/image/upload/v1703511741/app/year_in_review/2023/doodle/bdg3iudth0wsm4htqmry.png",
                        winter: "https://res.cloudinary.com/honeybook/image/upload/v1703511741/app/year_in_review/2023/doodle/bdg3iudth0wsm4htqmry.png"
                    },
                    titleTranslationKey: "HOME.STATES.YEAR_REVIEW._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.YEAR_REVIEW._SUBTITLE_",
                    linkText: "HOME.STATES.YEAR_REVIEW._LINK_",
                    state: {
                        goTo: this.AppStates.root_core_navigation_yearInReview,
                        params: {},
                        options: {}
                    },
                    analytics: this.AnalyticsService.analytics_events.year_in_review_clicked
                },
                year_review_otam: {
                    image: {
                        normal: "https://res.cloudinary.com/honeybook/image/upload/v1703511741/app/year_in_review/2023/doodle/bdg3iudth0wsm4htqmry.png",
                        winter: "https://res.cloudinary.com/honeybook/image/upload/v1703511741/app/year_in_review/2023/doodle/bdg3iudth0wsm4htqmry.png"
                    },
                    titleTranslationKey: "HOME.STATES.YEAR_REVIEW._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.YEAR_REVIEW._SUBTITLE_",
                    linkText: "HOME.STATES.YEAR_REVIEW._LINK_",
                    state: {
                        goTo: this.AppStates.root_core_navigation_yearInReview,
                        params: {},
                        options: {}
                    },
                    analytics: this.AnalyticsService.analytics_events.year_in_review_clicked,

                    secondarySubtitleTranslationKey: "HOME.STATES.YEAR_REVIEW_OTAM._SUBTITLE_",
                    secondaryLinkText: "HOME.STATES.YEAR_REVIEW_OTAM._LINK_",
                    secondaryState: {
                        goTo: AppStates.root_core_navigation_templates,
                        params: {pre_otam: 'inProduct'},
                        options: {}
                    },
                },
                otam_migration: {
                    image: {
                        normal: "https://res.cloudinary.com/honeybook/image/upload/fl_lossy/v1644481646/honeybook_cdn/otam/home_page_banner/cannon.gif",
                        winter: "https://res.cloudinary.com/honeybook/image/upload/fl_lossy/v1644481646/honeybook_cdn/otam/home_page_banner/cannon.gif"
                    },
                    titleTranslationKey: "HOME.STATES.OTAM_MIGRATION._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.OTAM_MIGRATION._SUBTITLE_",
                    linkText: "Try it!",
                    state: {
                        goTo: AppStates.root_core_navigation_templates,
                        params: {pre_otam: 'inProduct'},
                        options: {}
                    }
                },
                ooo_during_xmas: {
                    image: {
                        normal: randomHolidayDoodleUrl,
                        winter: randomHolidayDoodleUrl
                    },
                    titleTranslationKey: "HOME.STATES.OOO_DURING_XMAS._TITLE_",
                    subtitleTranslationKey: "HOME.STATES.OOO_DURING_XMAS._SUBTITLE_",
                    linkText: "HOME.STATES.OOO_DURING_XMAS._LINK_",
                    state: {
                        goTo: AppStates.root_core_navigation_settings_account,
                        params: { ooo: true},
                        options: {}
                    }
                }
            };

            Promise.all([yirPromise]).then(function (results) {
                this.currentState = calcState.call(this, this.OtamStatusService.isTeamMember());
                this.image = this.getImage();
                this.showYearInReview = showYearReviewDecorations;
            }.bind(this));

            function calcState(isTeamMember) {
                var currState = null;
                var currHour = parseFloat(moment().format("HH"));

                var SPLIT_MORNING   = 6;
                var SPLIT_AFTERNOON = 11;
                var SPLIT_EVENING   = 17;
                var SPLIT_NIGHT     = 22;

                // if (showOooBanner) {
                //     currState = states.ooo_during_xmas;
                // }
                if (showYearReviewDecorations && showOtamMigration) {
                    currState = states.year_review_otam;
                } else if (showYearReviewDecorations) {
                    currState = states.year_review;
                } else if (showOtamMigration) {
                    currState = states.otam_migration;
                    if (isTeamMember) {
                        delete currState.linkText;
                        delete currState.state;
                    }
                } else if (showBirthdayDecorations) {
                    currState = states.birthday;
                } else if (
                    currHour >= SPLIT_MORNING && currHour <= SPLIT_AFTERNOON
                ) {
                    currState = states.morning;
                } else if (
                    currHour >= SPLIT_AFTERNOON && currHour <= SPLIT_EVENING
                ) {
                    currState = states.afternoon;
                } else if (
                    currHour >= SPLIT_EVENING
                    && currHour <= SPLIT_NIGHT
                ) {
                    currState = states.evening;
                } else {
                    currState = states.night;
                }

                this.isLoading = false;

                return currState;
            }

            // we removed this as we didnt want to do this
            /*
            var _setIsAmericanUser = function _setIsAmericanUser() {
                this.isAmericanUser = this.company && this.company.country && this.company.country.toLowerCase() === "us";
            }.bind(this);


            if (this.company.wasFullyFetched()) {
                _setIsAmericanUser();
            } else {
                this.registerOnce(this.company, 'success', _setIsAmericanUser);
            }*/

            this.getImage = function getImage() {
                var image = {
                    url : this.currentState.image.normal
                };

                var currDoodle = DoodleService.getDoodle();

                switch(currDoodle){
                    case Enums.doodles.winter:
                        image.url = this.currentState.image.winter;
                        break;
                    default:
                        image.url = this.currentState.image.normal;
                }

                if (currDoodle === Enums.doodles.thanksgiving && this.isAmericanUser) {
                    image.url = "//res.cloudinary.com/honeybook/image/upload/v1574775307/Thanksgiving_1_xbts2e.svg";
                    image.title = "Happy Thanksgiving!";
                }

                return image;
            };

            this.onClick = function onClick(){
                this.$state.go(this.currentState.state.goTo, this.currentState.state.params, this.currentState.state.options);

                if(this.currentState.analytics){
                    this.AnalyticsService.trackClick(this, this.currentState.analytics);
                }
            };

            this.secondaryOnClick = function secondaryOnClick(){
                this.$state.go(this.currentState.secondaryState.goTo, this.currentState.secondaryState.params, this.currentState.secondaryState.options);

                if(this.currentState.secondaryAnalytics){
                    this.AnalyticsService.trackClick(this, this.currentState.secondaryAnalytics);
                }
            };

        }
    }

    Components.HomeWelcomeMessage = {
        controller: HomeWelcomeMessageComponent,
        templateUrl: 'angular/app/modules/core/features/home/welcome_message/welcome_message_component_template.html',
    };

}());
