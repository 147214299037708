(function () {
    "use strict";

    Controllers.CalendarAbstractController = class CalendarAbstractController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, CalendarService, NavigationService, AppStates, UsersManager, SchedulingService, StatsigService) {
            super($scope, $injector);
            this.__objectType = 'CalendarAbstractController';

            this.CalendarService = CalendarService;
            this.NavigationService = NavigationService;
            this.AppStates = AppStates;
            this.UsersManager = UsersManager;
            this.SchedulingService = SchedulingService;

            this.calendarView = this.CalendarService.CALENDAR_MONTH_VIEW;
            this.hbCalendar = {
                title: '',
                date: Date.now(),
                calView: ''
            };

            this.updateCalendarView = () => {};

            this.user = this.UsersManager.getCurrUser();
            this.isSchedulingEnabled = this.SchedulingService.isSchedulingEnabled(this.user);
            // this.isSchedulingEnabled = true;
            this.isSchedulingPageActive = this.NavigationService.isRouteActive(this.AppStates.root_core_navigation_scheduling);

            // ng2react - Calendar
            this.calendarVariant = 'pending';
            StatsigService.isGateEnabled('ng2react-calendar').then(function (isEnabled) {
                this.calendarVariant = isEnabled ? 'react' : 'angular';
            }.bind(this)).catch(function () {
                this.calendarVariant = 'angular';
            }.bind(this));
        }

        setCalendarView(view) {
            this.calendarView = view;
        }

        setCalendarActive() {
            this.isSchedulingPageActive = false;
        }

        setSchedulingActive() {
            this.isSchedulingPageActive = true;
        }

        toggleView () {
            if(this.isSchedulingPageActive) {
                this.$state.go(this.AppStates.root_core_navigation_scheduling, {});
            } else {
                this.$state.go(this.AppStates.root_core_navigation_calendar, {});
            }
        }

        toolbarViewChange (view) {
            if(this._callback) {
                this._callback('changeView', view);
            }
        }

        toolbarTodayChange () {
            if(this._callback) {
                this._callback('today');
            }
        }

        toolbarControlChange (direction) {
            if(this._callback) {
                if (direction === 'prev' || direction === 'next') {
                    this._callback('move', direction);
                } else {
                    this.toolbarSetDate(direction);
                }
            }
        }

        toolbarSetDate (date) {
            if(this._callback) {
                this._callback('gotoDate', date);
            }
        }

        onCalendarToolbarEvent(callback) {
            this._callback = callback;
        }
    };

}());
